import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
    },
  });

interface PageSubHeadingProps extends WithStyles<typeof styles> {
  title: string;
}

const PageSubHeading: React.SFC<PageSubHeadingProps> = props => {
  return (
    <Typography variant="h5" className={props.classes.heading}>
      {props.title}
    </Typography>
  );
};

export default withStyles(styles)(PageSubHeading);
