import * as React from 'react';

import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { NotFound } from '../containers/4xxMessages';

import { BaseLayout } from '../components/Layouts';

import AuthProvider, { AuthContext } from '../contexts/AuthContext';
// import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { /*Breadcrumb,*/ Breadcrumbs } from '../components/Breadcrumbs';
import routeList from './routeList';
import { Preview } from 'containers/Pim/Preview';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { primary, secondary } from 'config/colors';
import { ErrorBoundary } from 'components/ErrorBoundary';
import asyncComponent from 'components/AsyncComponent/AsyncComponent';
import { TitleText } from 'components/TitleText';

// auth
const AsyncAuth = asyncComponent(() => import('../containers/AuthPage'), true);

// import asyncComponent from 'components/AsyncComponent';

// tslint:disable-next-line:no-empty-interface
export interface RoutesProps {
  authState?: string;
  authData?: any;
  onStateChange?: (authState: string, data?: any) => void;
}

interface RoutesState {
  testAuth: boolean;
}

class Routes extends React.Component<RoutesProps, RoutesState> {
  constructor(props: RoutesProps) {
    super(props);
    this.state = {
      testAuth: true,
    };
  }

  render() {
    // tslint:disable-next-line:no-console

    // tslint:disable
    // window.props = this.props;
    // tslint:enable

    const {
      authState = 'signIn',
      authData,
      onStateChange = () => null,
    } = this.props;
    const isAuthenticated = this.props.authState === 'signedIn';

    if (isAuthenticated) {
      return (
        <>
          <AuthProvider
            authData={authData}
            authState={authState}
            onStateChange={onStateChange}
          >
            <Router basename={process.env.REACT_APP_BASE_URL}>
              <Route
                path={[
                  '/preview/:productGroupId/:productId',
                  '/preview/:productGroupId',
                ]}
              >
                <ThemeProvider
                  theme={createTheme({
                    breakpoints: {
                      values: {
                        xs: 0,
                        sm: 576,
                        md: 768,
                        lg: 992,
                        xl: 1200,
                      },
                    },
                    palette: {
                      primary: {
                        main: primary,
                      },
                      secondary: {
                        main: secondary,
                      },
                    },
                  })}
                >
                  <Preview />
                </ThemeProvider>
              </Route>
              <Route>
                {({ location }) => {
                  if (location.pathname.includes('/preview/')) {
                    return null;
                  }
                  return <CoreApp />;
                }}
              </Route>
            </Router>
          </AuthProvider>
        </>
      );
    }

    return null;
  }
}

export default Routes;

function CoreApp() {
  const auth = React.useContext(AuthContext);

  return (
    <BaseLayout>
      <Breadcrumbs routes={routeList} />
      <TitleText routes={routeList} />
      <ErrorBoundary>
        <Switch>
          <Route path="/auth" component={AsyncAuth} />
          {routeList
            .filter(r => !r.breadcrumbOnly)
            .map((route, i) => (
              <PrivateRoute
                key={i}
                component={route.component}
                path={route.path}
                isAuthenticated={
                  route.requiredRole
                    ? auth.hasPermission(route.requiredRole)
                    : false
                }
                exact={route.exact === false ? false : true} // to make undefined items still use exact
              />
            ))}
          <Route component={NotFound} />
        </Switch>
      </ErrorBoundary>
    </BaseLayout>
  );
}
