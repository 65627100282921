// taken from  https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html

import * as React from 'react';
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
// import withStyles from "@material-ui/core/styles/withStyles";
// import Theme from "@material-ui/core/styles/Theme";
// import WithStyles from "@material-ui/core/styles/WithStyles";
// import createStyles from "@material-ui/core/styles/createStyles";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
  });

interface AsyncComponentLoaderProps extends WithStyles<typeof styles> {
  showLoader: boolean;
}

const AsyncComponentLoader: React.SFC<AsyncComponentLoaderProps> = ({
  showLoader = false,
  classes,
}) => {
  if (!showLoader) {
    return null;
  }
  return <CircularProgress className={classes.progress} />;
};

export default withStyles(styles)(AsyncComponentLoader);

// const LoaderWrapper = styled('div')({
//   textAlign: 'center',
//   borderRadius: '4px',
//   marginBottom: '20px',
//   padding: '30px 50px',
//   margin: '20px 0',
// });
