// https://stackoverflow.com/questions/47747754/how-to-rewrite-the-protected-router-using-typescript-and-react-router-4

import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
// import { AuthContext } from '../contexts/AuthContext';
import { Unauthorised } from '../containers/4xxMessages';
// import { ApiPath } from 'types/endpoints';
// import { inject, observer } from 'mobx-react';
// import { AuthModel } from 'models';

interface PrivateRouteProps extends RouteProps {
  // [key: string]: any;
  component: any; // force errors to stop / total hack there must be a better way
  isAuthenticated: boolean;
  // auth?: AuthModel;
}

const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      // tslint:disable-next-line:jsx-no-lambda
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Unauthorised />
          // <Redirect
          //     to={{
          //         pathname: '/',
          //         state: { from: props.location }
          //     }}
          // />
        )
      }
    />
  );
};

export default PrivateRoute;

// interface WrappedPrivateRouteProps extends PrivateRouteProps {
//   requiredRole?: ApiPath;
// }

// // this is a wrapper of ther private route
// // component which allows us to check the
// // AuthContext to make sure a user has access

// export default (props: WrappedPrivateRouteProps) => {
//   const auth = React.useContext(AuthContext);

//   if (props.requiredRole) {
//     const role = props.requiredRole;

//     return (
//       <PrivateRoute {...props} isAuthenticated={auth.hasPermission(role)} />
//     );
//   }

//   return <PrivateRoute {...props} />;
// };
