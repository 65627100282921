import * as React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/Button';
import { Link, LinkProps } from 'react-router-dom';
import { AuthConsumer } from '../../contexts/AuthContext';

// tslint:disable-next-line:no-empty-interface
export interface AccountMenuProps {}
export interface AccountMenuState {
  anchorEl?: any; // ? EventTarget & Element | null
}

const ProfileLink = (props: Partial<LinkProps>) => (
  <Link {...props} to="/profile" />
);

class AccountMenu extends React.Component<AccountMenuProps, AccountMenuState> {
  constructor(props: AccountMenuProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event: React.MouseEvent) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;

    return (
      <>
        <IconButton
          tabIndex={0}
          aria-owns={anchorEl ? 'account-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>

        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose} component={ProfileLink}>
            Profile
          </MenuItem>
          <AuthConsumer>
            {({ signOut }) => <MenuItem onClick={signOut}>Sign Out</MenuItem>}
          </AuthConsumer>
        </Menu>
      </>
    );
  }
}

export default AccountMenu;
