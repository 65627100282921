import * as React from 'react';
import API from '@aws-amplify/api';
import { ApiName } from '../config/aws';
import { ApiPath } from '../types/endpoints';
import { ApiFilter, ApiSortRule } from '../helpers/filterBuilder';

export interface FetchItemsParams<T> {
  apiName: ApiName;
  apiPath: ApiPath;

  filters?: Array<ApiFilter<T>>;
  sort?: Array<ApiSortRule<T>>;
  pageNumber?: number;
  pageSize?: number;
  disabled?: boolean;
  body?: any;
  queryStringParams?: any;
}

export interface FetchItemsModel<T> {
  isLoading: boolean;
  items: T[];
  errors: string[];
  itemCount: number;
  pageCount: number;
  getItems: (disableLoader?: boolean | undefined) => void;
}

export function useFetchItems<T>({
  apiName,
  apiPath,
  filters,
  sort,
  pageNumber = 1,
  pageSize = 100,
  disabled = false,
  body,
  queryStringParams,
}: FetchItemsParams<T>): FetchItemsModel<T> {
  const [model, setModel] = React.useState<
    Omit<FetchItemsModel<T>, 'getItems'>
  >({
    isLoading: true,
    itemCount: 0,
    pageCount: 0,
    items: [],
    errors: [],
  });

  const timestamp = React.useRef<number | null>(null);

  const getData = React.useCallback(
    (disableLoader?: boolean) => {
      if (disabled) {
        return;
      }

      if (!disableLoader) {
        setModel(prev => ({
          ...prev,
          isLoading: true,
        }));
      }

      // get current time and set the time stamp
      const now = Date.now();
      timestamp.current = now;

      API.post(apiName, apiPath, {
        body: {
          pageNumber,
          pageSize,
          filters: filters,
          sortCriteria: sort,
          ...(body || {}),
        },
        queryStringParams,
      })
        .then(res => {
          // check timestamp - if different then don't return results
          if (timestamp.current !== now) {
            return;
          }

          if (res.success) {
            const itemCount = res.outRowCount || 0;
            const pageCount = Math.ceil(itemCount / pageSize);

            setModel(prev => ({
              ...prev,
              isLoading: false,
              errors: [],
              items: res.items || [],
              itemCount,
              pageCount,
            }));
          } else {
            setModel(prev => ({
              ...prev,
              isLoading: false,
              errors: res.errorMessages || [],
            }));
          }
        })
        .catch(err => {
          setModel(prev => ({
            ...prev,
            items: [],
            isLoading: false,
            errors: [err.message],
          }));
        });
    },
    [
      apiName,
      apiPath,
      body,
      disabled,
      filters,
      pageNumber,
      pageSize,
      queryStringParams,
      sort,
    ],
  );

  // console.log('do something');
  React.useEffect(() => {
    getData();
  }, [getData]);

  return React.useMemo(
    () => ({
      ...model,
      getItems: getData,
    }),
    [getData, model],
  );
}
