import * as React from 'react';

// import InlineLink from 'components/InlineLink';
import {
  WithStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Breadcrumb from './Breadcrumb';
import { RouteList } from '../../routes/routeList';

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(2),
      color: theme.palette.grey['400'],
      fontSize: theme.typography.body2.fontSize,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&:empty': {
        display: 'none',
      },
    },
  });

export interface BreadcrumbsProps extends WithStyles<typeof styles> {
  routes: RouteList;
}

class BreadCrumbs extends React.Component<BreadcrumbsProps, any> {
  public render() {
    const { routes } = this.props;

    return (
      <div data-cy="breadcrumbs" className={this.props.classes.wrapper}>
        {routes.map(bc => (
          <Breadcrumb
            key={bc.path}
            to={bc.path}
            breadcrumbOnly={bc.breadcrumbOnly}
            breadcrumbLinkDisabled={bc.breadcrumbLinkDisabled}
          >
            {bc.breadcrumb}
          </Breadcrumb>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(BreadCrumbs);
