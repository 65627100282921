import Link, { LinkProps } from '@material-ui/core/Link';
import * as React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export default class InlineLink extends React.Component<
  RouterLinkProps & LinkProps
> {
  public render() {
    return <Link component={RouterLink} {...(this.props as any)} />;
  }
}
