import * as React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Menu,
  Button,
  MenuItem,
} from '@material-ui/core';
import { ProductPreview } from 'api/products/model/models';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

export interface PreviewVariantListProps {
  productGroupId: number;
  productId?: number | null;
  productPreviews?: ProductPreview[];
}

export function PreviewVariantList(props: PreviewVariantListProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!props.productId && !props.productPreviews) {
    return null;
  }

  const currentVariant = props.productPreviews?.find(
    item => item.id === props.productId,
  );

  return (
    <div className={classes.root}>
      <Button
        aria-controls="variant-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
      >
        {currentVariant?.variantName || 'Select a Variant'}
      </Button>
      <Menu
        id="variant-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.productPreviews?.map(preview => (
          <MenuItem
            onClick={handleClose}
            key={preview.id}
            component={Link}
            to={`/preview/${props.productGroupId}/${preview.id}`}
          >
            {preview.variantName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
