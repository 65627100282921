import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

export interface ProgressSpinnerProps extends WithStyles<typeof styles> {
  spinnerProps?: CircularProgressProps;
  disablePadding?: boolean;
}

class ProgressSpinner extends React.Component<ProgressSpinnerProps, any> {
  public render() {
    const { classes } = this.props;

    // improve this later

    if (this.props.disablePadding) {
      return <CircularProgress {...this.props.spinnerProps} />;
    }

    return (
      <div className={classes.root}>
        <CircularProgress {...this.props.spinnerProps} />
      </div>
    );
  }
}

export default withStyles(styles)(ProgressSpinner);
