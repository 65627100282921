import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';

// note: primary secondary and error colours
// taken from Findel Education logo

export const primary = '#108CB1';
export const secondary = '#F78F21';

export const success = green['600'];
export const info = lightBlue[700];
export const warning = orange;
export const error = '#BC1F54';
