import * as React from 'react';
import { PageHeading } from '../../components/PageHeading';

// tslint:disable-next-line:no-empty-interface
interface NotFoundProps {}

const NotFound: React.SFC<NotFoundProps> = props => {
  return <PageHeading title="Page Not Found" />;
};

export default NotFound;
