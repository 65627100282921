import createMuiTheme, {
  ThemeOptions,
} from '@material-ui/core/styles/createTheme';

import { info, success, error } from '../config/colors';

// interface ExtendedTheme extends Theme {

// }

// need to extend theme based on

export default function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    ...options,
    palette: {
      ...options.palette,
      info: {
        main: info,
      },
      success: {
        main: success,
      },
      error: {
        main: error,
      },
    },
    // status:{
    //   info: info,
    //   success: success,
    //   error: error,
    // }
  });
}
