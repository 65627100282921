import * as React from 'react';
import API from '@aws-amplify/api';
import { ApiName } from '../config/aws';
import { ApiPath } from '../types/endpoints';

// interface ItemModel<T> {
//   item: T;
//   errors: string[];
// }

interface GetDataParams {
  method?: 'get';
  // consider adding a 'force call' parameter or allowinf querystring to be undefined for calls with no params or all empty params object ?
}

interface SharedParams<T> {
  apiName: ApiName;
  apiPath?: ApiPath;
  queryStringParams: Partial<T> | string | number | null; // using a non-object will map the value to ID // null doesn't call anything
  showAllData?: boolean;
  disabled?: boolean;
  /**
   * Callback
   * Use when need to trigger something based on returned values
   */
  callback?: (data: T) => void;
}

interface PostDataParams<T> {
  method: 'post';
  /**
   * BODY PARAMS
   * Not implemented yet
   */
  body?: Partial<T> | {};
}

type DataParams<T> =
  | (GetDataParams & SharedParams<T>)
  | (PostDataParams<T> & SharedParams<T>);

interface DataState<T> {
  isLoading: boolean;
  errors: string[];
  data: T | null;
}

export interface DataActions<T> {
  getData: () => void;
  setState: (data: DataState<T>) => void;
}

export type ItemRenderModel<T> = [DataState<T>, DataActions<T>];

export function useGetData<T>({
  apiName,
  apiPath,
  queryStringParams,
  showAllData = false,
  method = 'get',
  disabled = false,
  callback,
}: DataParams<T>) {
  React.useDebugValue(`${apiName}/${apiPath}`);

  const [state, setState] = React.useState<DataState<T>>({
    isLoading: true,
    errors: [],
    data: null,
  });

  const getData = React.useCallback(() => {
    let didCancel = false;

    if (didCancel) return;

    if (disabled) {
      return;
    }

    if (!apiPath) {
      return;
    }

    if (queryStringParams === null) {
      // setIsLoading(false);
      // setItem(null);
      setState({
        isLoading: false,
        data: null,
        errors: [],
      });
      return;
    }

    setState(prevState => {
      if (prevState.isLoading) {
        return prevState;
      }
      return {
        ...prevState,
        isLoading: true,
      };
    });

    const qs =
      typeof queryStringParams === 'object'
        ? queryStringParams
        : { id: queryStringParams };

    API[method](apiName, apiPath, {
      queryStringParameters: {
        ...qs,
      },
    })
      .then(res => {
        if (res.success) {
          const getDataFromResponse = () => {
            if (method === 'post') {
              return showAllData ? res : res.items;
            }
            return showAllData ? res : res.data;
          };

          const data = getDataFromResponse() || null;

          if (callback) {
            callback(data);
          }

          setState(prev => {
            const errors = prev.errors.length === 0 ? prev.errors : [];
            return {
              isLoading: false,
              data,
              errors,
            };
          });
        } else {
          setState({
            isLoading: false,
            data: null,
            errors: res.errorMessages || [],
          });
        }
      })
      .catch(err => {
        setState({
          isLoading: false,
          data: null,
          errors: [err.message],
        });
      });

    return () => {
      didCancel = true;
    };
  }, [
    apiName,
    apiPath,
    callback,
    disabled,
    method,
    queryStringParams,
    showAllData,
  ]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  // const [actions] = React.useState<ItemActions>({
  //   getData
  // })

  const ret: ItemRenderModel<T> = React.useMemo(
    () => [
      state,
      // actions
      {
        getData,
        setState,
      },
    ],
    [getData, state],
  );

  return ret;
}
