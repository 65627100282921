import * as React from 'react';

import { ProductGroupForWorkflow } from 'api/products/model/models';
import { useGetData, ItemRenderModel } from 'hooks/useGetData';

export interface NpiContextState
  extends ItemRenderModel<ProductGroupForWorkflow> {}

export interface NpiContextProviderProps {
  npiId: number | null;
  children: React.ReactNode;
}

const initialState: NpiContextState = [
  {
    errors: [],
    isLoading: true,
    data: null,
  },
  {
    getData: () => {},
    setState: () => {},
  },
];

// type QueryStringParams = Partial<NpiProduct> | null;

export const NpiContext = React.createContext<NpiContextState>(initialState);

export function NpiContextProvider(props: NpiContextProviderProps) {
  const qsParams = React.useMemo(() => {
    return props.npiId ? { id: props.npiId } : null;
  }, [props.npiId]);

  const npiItem = useGetData<ProductGroupForWorkflow>({
    apiName: 'Products',
    apiPath: 'ProductGroupForWorkfow',
    queryStringParams: qsParams,
  });

  return (
    <NpiContext.Provider value={npiItem}>{props.children}</NpiContext.Provider>
  );
}

export const NpiContextConsumer = NpiContext.Consumer;
