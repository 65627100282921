import * as React from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
// https://material.io/tools/icons/?icon=3d_rotation&style=baseline
import AccountIcon from '@material-ui/icons/Person';
import SupplierUserIcon from '@material-ui/icons/PermContactCalendar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttributesIcon from '@material-ui/icons/EditAttributes';
import NpiIcon from '@material-ui/icons/AddToPhotos';
import PimIcon from '@material-ui/icons/Storefront';
import DataQualityIcon from '@material-ui/icons/DataUsage';
import DataExtractIcon from '@material-ui/icons/CloudDownload';
import ImageProcessIcon from '@material-ui/icons/ImageSearch';
import RolesIcon from '@material-ui/icons/Group';
import SuppliersIcon from '@material-ui/icons/LocationCity';
import SysConfigIcon from '@material-ui/icons/Settings';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import {
//   Link,
//   LinkProps,
// } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import ToggleTheme from '../ToggleTheme';
import { NavItem } from '../../components/Navigation';
import { drawerWidth } from './TopNavigation';
import { EnvironmentInfo } from './EnvironmentInfo';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
    },
    sideBarNav: {
      marginBottom: 66,
    },
    themeToggle: {
      backgroundColor: theme.palette.background.paper,
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: drawerWidth,
      zIndex: 1,
    },
  });

interface SideNavigationProps extends WithStyles<typeof styles> {}

const SideNavigation: React.SFC<SideNavigationProps> = props => {
  const { classes } = props;

  const auth = React.useContext(AuthContext);

  const { isFindelUser, hasPermission } = auth;

  // @todo: remove this when permissions added
  // hack to hide nav from buyers
  // const isNotUatOrLive = !(
  //   window.location.hostname.includes('prodigi-uat.findel.education') ||
  //   window.location.hostname.includes('prodigi.findel.education')
  // );

  const showNpi = hasPermission('InsertProductGroup') || !isFindelUser;

  const showPim = isFindelUser && hasPermission('ProductGroupPimContentDetail');

  const showDataQualityReport =
    isFindelUser && hasPermission('GetProductGroupQualityScoreReport');

  const showProductDataExtract =
    isFindelUser && hasPermission('GetProductsDataExtractDetails');

  const showImageLog =
    isFindelUser && hasPermission('ProductImageProcessLogDetails');

  const showAttributes = isFindelUser && hasPermission('ProductAttribute');
  const showProductConfigs =
    isFindelUser && hasPermission('InsertSystemUserSupplier');

  const showProductSections =
    showNpi || showPim || showAttributes || showProductConfigs;

  // const showRoles = isFindelUser && hasPermission('InsertSystemRole');
  const showRoles = isFindelUser && hasPermission('SystemRoles');
  const showFindelUsers =
    isFindelUser && hasPermission('InsertSystemUserFindel');

  const showUserAdmin = showRoles || showFindelUsers;

  const showSuppliers =
    isFindelUser && hasPermission('InsertSystemUserSupplier');

  const showSupplierUsers =
    isFindelUser && hasPermission('InsertSystemUserSupplier');

  const showSupplierSection = showSupplierUsers || showSuppliers;

  const showOffers =
    isFindelUser && hasPermission('ProductOfferDetailForTable');

  const handleNavClick = () => {
    console.log('Nav Clicked');
  };

  return (
    <div className={classes.root}>
      <div className={classes.sideBarNav}>
        <List component="nav">
          <NavItem
            to="/"
            activeOnlyWhenExact={true}
            onClick={handleNavClick}
            // ContainerComponent={DashLink}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </NavItem>
        </List>
        <Divider />
        {showProductSections && (
          <List component="nav">
            <ListSubheader disableSticky={true}>Products</ListSubheader>
            {showNpi && (
              <NavItem
                to="/npi"
                // ContainerComponent={NpiLink}
              >
                <ListItemIcon>
                  <NpiIcon />
                </ListItemIcon>
                <ListItemText title="New Product Introduction" primary="NPI" />
              </NavItem>
            )}
            {showPim && (
              <NavItem
                to="/pim"
                // ContainerComponent={NpiLink}
              >
                <ListItemIcon>
                  <PimIcon />
                </ListItemIcon>
                <ListItemText
                  title="Product Information Management"
                  primary="PIM"
                />
              </NavItem>
            )}
            {showAttributes && (
              <NavItem to="/manage-attributes">
                <ListItemIcon>
                  <AttributesIcon />
                </ListItemIcon>
                <ListItemText primary="Attributes" />
              </NavItem>
            )}
            {showProductConfigs && (
              <NavItem
                to="/product-config"
                // ContainerComponent={ProductConfigLink}
              >
                <ListItemIcon>
                  <SysConfigIcon />
                </ListItemIcon>
                <ListItemText primary="Product Config" />
              </NavItem>
            )}
            {showOffers && (
              <NavItem
                to="/offers"
                // ContainerComponent={ProductConfigLink}
              >
                <ListItemIcon>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary="Offers" />
              </NavItem>
            )}
          </List>
        )}
        {(showDataQualityReport || showProductDataExtract || showImageLog) && (
          <List component="nav">
            <ListSubheader disableSticky={true}>Reports</ListSubheader>
            {showDataQualityReport && (
              <NavItem
                to="/reports/data-quality-report"
                // ContainerComponent={NpiLink}
              >
                <ListItemIcon>
                  <DataQualityIcon />
                </ListItemIcon>
                <ListItemText primary="Data Quality" />
              </NavItem>
            )}
            {showProductDataExtract && (
              <NavItem
                to="/reports/product-data-extract"
                // ContainerComponent={NpiLink}
              >
                <ListItemIcon>
                  <DataExtractIcon />
                </ListItemIcon>
                <ListItemText primary="Product Data Extract" />
              </NavItem>
            )}
            {showImageLog && (
              <NavItem
                to="/reports/image-process-log"
                // ContainerComponent={NpiLink}
              >
                <ListItemIcon>
                  <ImageProcessIcon />
                </ListItemIcon>
                <ListItemText primary="Image Processing" />
              </NavItem>
            )}
          </List>
        )}
        {showUserAdmin && (
          <List component="nav">
            <ListSubheader disableSticky={true}>
              User Administration
            </ListSubheader>
            {showRoles && (
              <NavItem
                to="/roles"
                // ContainerComponent={RolesLink}
              >
                <ListItemIcon>
                  <RolesIcon />
                </ListItemIcon>
                <ListItemText primary="Roles" />
              </NavItem>
            )}
            {showFindelUsers && (
              <NavItem
                to="/users/findel"
                // ContainerComponent={UsersLink}
              >
                <ListItemIcon>
                  <AccountIcon />
                </ListItemIcon>
                <ListItemText primary="Findel Users" />
              </NavItem>
            )}
          </List>
        )}
        {showSupplierSection && (
          <List component="nav">
            <ListSubheader disableSticky={true}>
              Supplier Administration
            </ListSubheader>
            {showSuppliers && (
              <NavItem
                to="/suppliers"
                // ContainerComponent={SuppliersLink}
              >
                <ListItemIcon>
                  <SuppliersIcon />
                </ListItemIcon>
                <ListItemText primary="Suppliers" />
              </NavItem>
            )}
            {showSupplierUsers && (
              <NavItem
                to="/users/supplier"
                // ContainerComponent={UsersLink}
              >
                <ListItemIcon>
                  <SupplierUserIcon />
                </ListItemIcon>
                <ListItemText primary="Supplier Users" />
              </NavItem>
            )}
          </List>
        )}
      </div>
      <div className={classes.themeToggle}>
        <Divider />
        <List>
          <ToggleTheme />
        </List>

        <EnvironmentInfo />
      </div>
    </div>
  );
};

export default withStyles(styles)(SideNavigation);
