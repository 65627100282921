import * as React from 'react';
import { ThemeConsumer } from '../contexts/ThemeContext';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

// tslint:disable-next-line:no-empty-interface
interface ToggleThemeProps {}

const ToggleTheme: React.SFC<ToggleThemeProps> = props => {
  return (
    <ThemeConsumer>
      {({ darkMode, toggleTheme }) => (
        <MenuItem button={true} tabIndex={0} onClick={toggleTheme}>
          <FormControlLabel
            label="Toggle Theme"
            control={<Switch checked={darkMode} />}
            onClick={toggleTheme}
          />
        </MenuItem>
      )}
    </ThemeConsumer>
  );
};

export default ToggleTheme;
