import React from 'react';
import { Chip, useTheme, useMediaQuery } from '@material-ui/core';

export function ScreenSizeChip() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));

  return (
    <Chip
      variant="outlined"
      color="secondary"
      size="small"
      label={
        <>
          {(xs && 'Mobile') || null}
          {(sm && 'Phablet') || null}
          {(md && 'Tablet') || null}
          {(lg && 'Small Desktop') || null}
          {(xl && 'Large Desktop') || null}
        </>
      }
    />
  );
}
