import * as React from 'react';
import InlineAlert from './InlineAlert';

export interface ErrorBoundaryProps {}

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.log('Caught Error', error);
    return { hasError: true, error };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <InlineAlert
          variant="error"
          message={
            <>
              <div>An Error Occurred: {this.state.error?.name}</div>
              <div>{this.state.error?.message}</div>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}
