import { ScreenSizeChip } from './components/ScreenSizeChip';
import * as React from 'react';
import {
  // ThemeProvider,
  // createMuiTheme,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import logo from 'images/findel-logo.svg';
import { Container, Typography } from '@material-ui/core';
import { PreviewScreen } from './components/PreviewScreen';
import { useParams } from 'react-router-dom';
import { NpiContextProvider } from 'containers/Npi/components/NpiContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      minHeight: '100vh',
    },
    header: {
      position: 'relative',
    },
    logoWrapper: {
      height: 100,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    logo: {
      maxWidth: 200,
    },
    nav: {
      height: 42,
      backgroundColor: theme.palette.grey[300],
    },
    breakpoints: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }),
);

export interface PreviewProps {}

export function Preview(props: PreviewProps) {
  let { productGroupId, productId } = useParams<{
    productGroupId: string;
    productId?: string;
  }>();

  const parsedProductGroupId = parseInt(productGroupId, 10);
  const parsedProductId = productId ? parseInt(productId, 10) : null;

  console.log(parsedProductGroupId, parsedProductId);

  const classes = useStyles();

  if (!parsedProductGroupId) {
    return <Typography>Invalid product</Typography>;
  }

  return (
    <NpiContextProvider npiId={parsedProductGroupId}>
      <div className={classes.wrapper}>
        <header className={classes.header}>
          <Container maxWidth="xl">
            <div className={classes.logoWrapper}>
              <img className={classes.logo} src={logo} alt="Logo" />
            </div>
          </Container>

          <div className={classes.breakpoints}>
            <ScreenSizeChip />
          </div>
        </header>
        <nav className={classes.nav}></nav>
        <Container maxWidth="xl">
          <PreviewScreen
            productGroupId={parsedProductGroupId}
            productId={parsedProductId}
          />
        </Container>
      </div>
    </NpiContextProvider>
  );
}
