import * as React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'absolute',
      marginLeft: drawerWidth,
      color: theme.palette.primary.main,
      // boxShadow: 'none',
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    topIcons: {
      marginLeft: 'auto',
    },
    navIconHide: {
      marginRight: 12,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  }),
);

export interface TopNavigationProps {
  handleDrawerToggle: any;
  titleContent: React.ReactNode;
  menuContent?: React.ReactNode;
}
function TopNavigation(props: TopNavigationProps) {
  const classes = useStyles();
  const { titleContent, menuContent } = props;

  return (
    <AppBar className={classes.appBar} color="default">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerToggle}
          className={classes.navIconHide}
        >
          <MenuIcon />
        </IconButton>
        {titleContent}
        <div className={classes.topIcons}>{menuContent}</div>
      </Toolbar>
    </AppBar>
  );
}

export default TopNavigation;
