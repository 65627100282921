import * as React from 'react';

export interface ThemeContextState {
  darkMode: boolean;
  toggleTheme: (e: React.MouseEvent) => void;
}

const storedThemeMode = JSON.parse(localStorage.getItem('darkMode') || 'false');

const store: ThemeContextState = {
  darkMode: storedThemeMode || false,
  toggleTheme: () => false,
};

export const ThemeContext = React.createContext(store);

export default class ThemeProvider extends React.Component<
  {},
  ThemeContextState
> {
  constructor(props: {}) {
    super(props);

    this.toggleTheme = this.toggleTheme.bind(this);

    this.state = {
      ...store,
      toggleTheme: this.toggleTheme,
    };
  }

  toggleTheme = (e: React.MouseEvent) => {
    e.preventDefault();
    // tslint:disable-next-line:no-console

    this.setState({ darkMode: !this.state.darkMode }, () => {
      localStorage.setItem('darkMode', JSON.stringify(this.state.darkMode));
    });
  };

  componentDidMount() {
    //
  }

  render() {
    const { children } = this.props;
    return (
      <ThemeContext.Provider value={this.state}>
        {children}
      </ThemeContext.Provider>
    );
  }
}

export const ThemeConsumer = ThemeContext.Consumer;
