import * as React from 'react';
import {
  Typography,
  Grid,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Button,
  InputLabel,
  Backdrop,
  Link,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useGetData } from 'hooks/useGetData';
import { useFetchItems } from 'hooks/useFetchItems';
import {
  ProductProduct,
  ProductGroupPimContentModel,
  ProductDataBuyerSetupModel,
  ProductGroupPreview,
  ProductCategoryDetailsModel,
} from 'api/products/model/models';

import Chevron from '@material-ui/icons/ChevronRight';
import { NpiContext } from 'containers/Npi/components/NpiContext';
import { ApiFilter } from 'helpers/filterBuilder';
import { RawHtml } from '@findel-education/findel.ui.components';
import { MediaPreview } from './MediaPreview';
import { DirectDeliveryBanner } from './DirectDeliveryBanner';
import ProgressSpinner from 'components/ProgressSpinner';
import { PreviewVariantList } from './PreviewVariantList';
import { FormDivider } from 'components/Inputs/FormDivider';
import { CurriculumMappingSelection } from 'components/CurriculumMapping/CurriculumMappingSelection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      // padding: theme.spacing(2), // shouldn't be needed now
    },
    imagePlaceholder: {
      position: 'relative',
      backgroundColor: theme.palette.grey[100],
      paddingTop: '100%',
    },
    pricingPlaceholder: {
      marginBottom: theme.spacing(2),
      fontSize: theme.typography.h6.fontSize,
      [theme.breakpoints.up('lg')]: {
        textAlign: 'right',
      },
    },
    buttonWrapper: {
      marginTop: theme.spacing(2),
    },
    variantLabel: {
      marginRight: theme.spacing(1),
    },
    backdrop: {
      zIndex: 10,
      color: '#fff',
    },
    breadcrumbs: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    breadcrumbChevron: {
      verticalAlign: 'middle',
    },
    table: {
      '& tbody tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '& td': {
        borderBottom: '0 none',
      },
    },
    richTextBody: {
      '& > div > *': {
        marginTop: 0,
      },
    },
  }),
);

export interface PreviewScreenProps {
  productId?: number | null;
  productGroupId: number;
}

export function PreviewScreen({
  productGroupId,
  productId,
}: PreviewScreenProps) {
  const classes = useStyles();
  const [productGroup] = React.useContext(NpiContext);

  const productCategoryId =
    (productGroup.data && productGroup.data.productCategoryId) || null;

  const productFilters: ApiFilter<ProductProduct>[] = React.useMemo(() => {
    if (!productGroupId) {
      return [];
    }

    return [
      {
        columnName: 'productGroupId',
        operator: 'And',
        searchType: 'Equals',
        searchValue: productGroupId,
      },
      {
        columnName: 'isActive',
        operator: 'And',
        searchType: 'Equals',
        searchValue: true,
      },
    ];
  }, [productGroupId]);

  const allProducts = useFetchItems<ProductProduct>({
    apiName: 'Products',
    apiPath: 'ProductProducts',
    disabled: !productGroupId,
    filters: productFilters,
    pageSize: 1000,
  });

  const queryStringParams = React.useMemo<any>(() => {
    if (!productId) {
      return { productGroupId };
    }
    return { productGroupId, productId };
  }, [productGroupId, productId]);

  const [
    currentProduct,
    // currentProductActions
  ] = useGetData<ProductGroupPreview>({
    apiName: 'Products',
    apiPath: 'GetProductGroupPreview',
    queryStringParams,
  });

  const firstProduct =
    allProducts.items && allProducts.items.length > 0
      ? allProducts.items[0]
      : null;

  const productGroupQsParams = React.useMemo<
    Partial<ProductGroupPimContentModel>
  >(() => {
    return {
      productGroupId: productGroupId || 0,
    };
  }, [productGroupId]);

  const [productGroupContent] = useGetData<ProductGroupPimContentModel>({
    apiName: 'Products',
    apiPath: 'ProductGroupPimContentDetail',
    queryStringParams: productGroupQsParams,
    disabled: !productGroupId,
  });

  const categoryQueryStringParams = React.useMemo(
    () => ({
      categoryId: productCategoryId,
    }),
    [productCategoryId],
  );

  const [baseCategory] = useGetData<ProductCategoryDetailsModel>({
    apiName: 'Products',
    apiPath: 'GetProductCategoryDetails',
    queryStringParams: categoryQueryStringParams as any,
    disabled: !productCategoryId,
  });

  const [
    selectedProduct,
    setSelectedProduct,
  ] = React.useState<ProductProduct | null>(null);

  // set the selected product to the first one
  React.useEffect(() => {
    if (firstProduct) {
      setSelectedProduct(firstProduct);
    }
  }, [firstProduct]);

  const productTitle = currentProduct.data?.title || null;
  const itemCode = currentProduct.data?.itemCode || null;

  const [productBuyerInfo] = useGetData<ProductDataBuyerSetupModel>({
    apiName: 'Products',
    apiPath: 'ProductDataBuyerSetup',
    queryStringParams: (selectedProduct && selectedProduct.id) || null,
    disabled: !selectedProduct,
  });

  const isDirect =
    productBuyerInfo.data?.productDataBuyerSetup?.isDirect || false;

  // const handleSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
  //   const productId = e.target.value;
  //   const parsedProductId = parseInt(productId as string) || 0;
  //   const product =
  //     allProducts.items.find(p => p.id === parsedProductId) || null;
  //   setSelectedProduct(product);
  // };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const title = productTitle && (
    <Box marginBottom={2}>
      <Typography component="h1" variant="h4" color="textPrimary">
        {productTitle}
      </Typography>
      <Typography component="p" variant="subtitle1" color="textSecondary">
        Product Code: {itemCode}
      </Typography>
    </Box>
  );

  const pricingPlaceholder = (
    <div className={classes.pricingPlaceholder}>
      <Typography variant="inherit" color="textPrimary">
        £#.##{' '}
        <Typography variant="inherit" color="textSecondary" component="span">
          ex VAT
        </Typography>
      </Typography>
      <div className={classes.buttonWrapper}>
        <Button fullWidth variant="contained" color="primary" size="large">
          Add to Basket
        </Button>
      </div>
    </div>
  );

  const isLoading =
    productBuyerInfo.isLoading ||
    currentProduct.isLoading ||
    productGroupContent.isLoading;

  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.breadcrumbs}
        component="div"
      >
        {baseCategory.isLoading ? (
          <ProgressSpinner disablePadding spinnerProps={{ size: 18 }} />
        ) : (
          <>
            Products
            {baseCategory.data?.parentCategories?.map(category => (
              <React.Fragment key={category.id}>
                <Chevron className={classes.breadcrumbChevron} />{' '}
                {category.name}
              </React.Fragment>
            ))}
          </>
        )}
      </Typography>
      <article className={classes.wrapper}>
        <Backdrop open={isLoading} className={classes.backdrop}>
          <ProgressSpinner spinnerProps={{ color: 'inherit' }} />
        </Backdrop>
        <Hidden mdUp>{title}</Hidden>
        <Grid container spacing={4}>
          <Grid xs={12} md={5} lg={6} item>
            <MediaPreview
              images={currentProduct.data?.images ?? undefined}
              videos={currentProduct.data?.videos ?? undefined}
              thumbnailPosition={isDesktop ? 'left' : 'bottom'}
            />
          </Grid>
          <Grid xs={12} md={7} lg={6} item>
            <Hidden smDown>{title}</Hidden>
            {isDirect && <DirectDeliveryBanner />}
            {allProducts.items.length > 1 && (
              <Box marginBottom={2} alignItems="center" display="flex">
                <InputLabel
                  id="variant-select-label"
                  className={classes.variantLabel}
                  htmlFor="variant-select"
                >
                  Variant
                </InputLabel>

                <PreviewVariantList
                  productGroupId={productGroupId}
                  productId={productId}
                  productPreviews={
                    currentProduct.data?.productPreviews ?? undefined
                  }
                />
              </Box>
            )}
            {pricingPlaceholder}
          </Grid>
        </Grid>
        <FormDivider />
        <Box mt={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Product Description
                  </Typography>
                  {currentProduct.data?.description ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component={'div'}
                      className={classes.richTextBody}
                    >
                      <RawHtml>{currentProduct.data?.description}</RawHtml>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No product description
                    </Typography>
                  )}
                </Box>
              </div>

              <div>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Reasons to Love
                  </Typography>
                  {currentProduct.data?.reasonsToLove ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component={'div'}
                      className={classes.richTextBody}
                    >
                      <RawHtml>{currentProduct.data?.reasonsToLove}</RawHtml>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No product description
                    </Typography>
                  )}
                </Box>
              </div>
              <div>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Learning Outcomes
                  </Typography>
                  {currentProduct.data?.learningOutcomes ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component={'div'}
                      className={classes.richTextBody}
                    >
                      <RawHtml>{currentProduct.data?.learningOutcomes}</RawHtml>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No learning outcomes
                    </Typography>
                  )}
                </Box>
              </div>
              <div>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Top Tips
                  </Typography>
                  {currentProduct.data?.topTips ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component={'div'}
                      className={classes.richTextBody}
                    >
                      <RawHtml>{currentProduct.data?.topTips}</RawHtml>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No top Tips
                    </Typography>
                  )}
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <Box marginBottom={2}>
                  <Typography gutterBottom component="h2" variant="h6">
                    Further Information
                  </Typography>
                  {currentProduct.data?.features &&
                  currentProduct.data?.features.length > 0 ? (
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        {currentProduct.data?.features?.map(
                          (feature, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell variant="head">
                                  {feature.title}
                                </TableCell>
                                <TableCell>{feature.description}</TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No Further Information
                    </Typography>
                  )}
                </Box>
              </div>
              <div>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Useful Links
                  </Typography>
                  {currentProduct.data?.links &&
                  currentProduct.data?.links.length > 0 ? (
                    currentProduct.data?.links?.map((link, index) => {
                      return (
                        <Box mb={0.5} key={index}>
                          <Link
                            href={link.url ?? ''}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.title}
                          </Link>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No Useful Links
                    </Typography>
                  )}
                </Box>
              </div>
              <div>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Documents
                  </Typography>
                  {currentProduct.data?.documents &&
                  currentProduct.data?.documents?.length > 0 ? (
                    currentProduct.data?.documents?.map((document, index) => {
                      return (
                        <Box mb={0.5} key={document.id}>
                          <Link
                            href={`https://${process.env.REACT_APP_PRODUCT_MEDIA_BUCKET}.s3.eu-west-2.amazonaws.com/${document.filekey}`}
                          >
                            {document.name}
                          </Link>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      No Documents
                    </Typography>
                  )}
                </Box>
              </div>
            </Grid>
            {currentProduct.data?.productCurriculumDetails &&
              currentProduct.data?.productCurriculumDetails.length > 0 && (
                <Grid item xs={12}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    This product helps teach the following National Curriculum
                    objectives:
                  </Typography>
                  <div>
                    {currentProduct.data?.productCurriculumDetails?.map(
                      currDetail => (
                        <Box mb={1}>
                          <CurriculumMappingSelection
                            title={`${currDetail.keyStageDisplayTitle} ${
                              currDetail.years ? `(${currDetail.years})` : ''
                            } > ${currDetail.subjectPath}`}
                            subTitle={currDetail.programme ?? ''}
                          />
                        </Box>
                      ),
                    )}
                  </div>
                </Grid>
              )}
          </Grid>
        </Box>
      </article>
    </>
  );
}
