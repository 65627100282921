import * as React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { RouteList } from 'routes/routeList';

export interface TitleTextProps {
  routes: RouteList;
}

export function TitleText(props: TitleTextProps) {
  const l = useLocation();

  React.useEffect(() => {
    const matches = props.routes.map(r => ({
      title: r.breadcrumb,
      matchPath: matchPath(l.pathname, { path: r.path, exact: true }),
    }));

    if (matches) {
      const onlyMatched = matches.filter(item => !!item.matchPath);

      if (onlyMatched.length > 0) {
        document.title = `${onlyMatched.reverse()[0].title} | ProDigi` || '';
        return;
      }
    }

    document.title = `ProDigi`;
  }, [l.pathname, props.routes]);

  // const titles = props.routes

  return null;
}
