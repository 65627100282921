import {
  Tooltip,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  TooltipProps,
} from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: '0.3s',
      backgroundColor: theme.palette.background.default,
    },
    text: {
      fontWeight: 'bold',
    },
  }),
);

export interface EnvironmentInfoProps {
  tooltipPlacement?: TooltipProps['placement'];
}

export function EnvironmentInfo({ tooltipPlacement }: EnvironmentInfoProps) {
  const classes = useStyles();

  const environment = process.env.REACT_APP_BUILD_ENVIRONMENT;

  if (environment === 'prod') {
    return null;
  }

  const getColor = () => {
    if (
      environment === 'local' ||
      environment === 'dev' ||
      environment?.includes('dev-prod-')
    ) {
      return 'success';
    }

    if (environment === 'uat') {
      return 'warning';
    }

    return 'error';
  };

  return (
    <Tooltip
      title={
        <div>
          <div>Build Time: {process.env.REACT_APP_BUILD_TIME || 'N/A'}</div>
          <div>Build Number: {process.env.REACT_APP_BUILD_NUMBER || 'N/A'}</div>
        </div>
      }
      placement={tooltipPlacement}
    >
      <Box
        className={classes.root}
        textAlign="center"
        color={`${getColor()}.main`}
        py={0.5}
      >
        <Typography className={classes.text} color="inherit" variant="body2">
          {environment?.toUpperCase()}
        </Typography>
      </Box>
    </Tooltip>
  );
}
