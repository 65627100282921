import Auth from '@aws-amplify/auth';

export type ApiName = 'Products' | 'Systems' | 'Suppliers';

const productsApi = process.env.REACT_APP_PRODUCTS_API_FUNCTION;
const suppliersApi = process.env.REACT_APP_SUPPLIERS_API_FUNCTION;
const systemsApi = process.env.REACT_APP_SYSTEMS_API_FUNCTION;

interface ApiEndpointConfig {
  name: ApiName;
  endpoint: string;
  service?: string;
  region?: string;
  custom_header?: () => { [x: string]: any };
}

type ApiEndpoints = ApiEndpointConfig[];

export const getAuth = async (customHeaders: {} = {}) => {
  const currentSession = await Auth.currentSession();
  const jwt = currentSession.getAccessToken().getJwtToken();
  const idToken = currentSession.getIdToken().getJwtToken();

  return {
    Authorization: jwt,
    Identification: idToken,
    ...customHeaders,
  };
};

// api switcher
export const apiEndpoints: ApiEndpoints = [
  {
    name: 'Products', // ✅
    endpoint: `${productsApi}/`,
    custom_header: () => getAuth(),
  },
  {
    name: 'Suppliers', // ✅
    endpoint: `${suppliersApi}/`,
    custom_header: () => getAuth(),
  },
  {
    name: 'Systems', // ✅
    endpoint: `${systemsApi}/`,
    custom_header: () => getAuth(),
  },
];

export function getApiBase(apiName?: ApiName) {
  if (!apiName) {
    return undefined;
  }
  const endpoint = apiEndpoints.find(path => path.name === apiName);
  if (!endpoint) {
    return undefined;
  }
  return endpoint.endpoint;
}

const baseUrl = window.location.origin;

export const environment = {
  amplify: {
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AUTH_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        // domain: '{{cognito_domain_replacement}}',
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        scope: [
          'email',
          'openid',
          'profile',
          'phone',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: `${baseUrl}/auth`,
        redirectSignOut: `${baseUrl}/`,
        responseType: 'code', // or token
      },
    },
    API: {
      // see https://aws-amplify.github.io/amplify-js/media/api_guide#custom-request-headers for auth example
      // dev
      endpoints: apiEndpoints,
    },
  },
};
