import * as React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // marginBottom: theme.spacing(2),
    },
  }),
);

interface PageHeadingProps {
  title?: React.ReactNode;
  className?: string;
  'data-cy'?: string;
}

const PageHeading: React.SFC<PageHeadingProps> = props => {
  const classes = useStyles();
  return (
    <Typography
      component="h1"
      variant="h4"
      className={classes.root + ' ' + props.className}
      data-cy={props['data-cy']}
      color="textPrimary"
      gutterBottom
    >
      {props.title || props.children}
    </Typography>
  );
};

export default PageHeading;
