import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { imageUrlEncoder } from 'helpers/imageUrlEncoder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { paddingTop: '100%', position: 'relative' },
    image: {
      position: 'absolute',
      backgroundColor: '#f7f7f7',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  }),
);

export interface PreviewCarouselImageProps {
  imageKey?: string;
  className?: string;
}

export function PreviewCarouselImage(props: PreviewCarouselImageProps) {
  const classes = useStyles();

  const handleOnDragStart = (e: React.DragEvent<HTMLImageElement>) =>
    e.preventDefault();

  if (!props.imageKey) {
    return <div style={{ paddingTop: '100%', position: 'relative' }}></div>;
  }

  const imageSrc = imageUrlEncoder({
    format: 'jpeg',
    key: props.imageKey,
    resize: {
      fit: 'contain',
      background: { r: 255, g: 255, b: 255, alpha: 1 },
      height: 512,
      width: 512,
    },
  });

  return (
    <div className={[classes.root, props.className].join(' ')}>
      <img
        src={imageSrc}
        alt={''}
        className={classes.image}
        onDragStart={handleOnDragStart}
      />
    </div>
  );
}
