import * as React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  Typography,
  IconButton,
} from '@material-ui/core';
import { CloseIcon, DragHandleIcon } from 'components/Icons';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      display: 'grid',
      gap: `${theme.spacing(1)}px`,
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'auto max-content',
        gap: `${theme.spacing(2)}px`,
      },
    },
    content: {},
    actions: {
      alignItems: 'center',
      display: 'flex',
    },
    handle: {
      color: theme.palette.text.hint,
    },
    handleWrapper: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.fontSize,
      },
    },
    subtitle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.fontSize - 2,
      },
    },
  }),
);

export interface CurriculumMappingSelectionProps {
  onDelete?: () => void;
  isDisabled?: boolean;
  isDragging?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  title?: React.ReactText;
  subTitle?: React.ReactText;
  customActions?: React.ReactNode;
}

export const CurriculumMappingSelection = React.memo(
  function CurriculumMappingSelection(props: CurriculumMappingSelectionProps) {
    const classes = useStyles();

    React.useEffect(() => {
      console.log('!== CurriculumMappingSelection mounting ==');
      return () => console.log('!== CurriculumMappingSelection unmounting ==');
    }, []);

    return (
      <Card variant="outlined" className={classes.root}>
        <div className={classes.content}>
          <div>
            <Typography
              className={classes.title}
              component="h4"
              variant="subtitle1"
              color="textPrimary"
            >
              {props.title}
            </Typography>
            {props.subTitle && (
              <Typography
                className={classes.subtitle}
                variant="subtitle2"
                color="textSecondary"
              >
                {props.subTitle}
              </Typography>
            )}
          </div>
        </div>
        {!props.isDisabled &&
          (props.onDelete || props.dragHandleProps || props.customActions) && (
            <div className={classes.actions}>
              {!props.isDisabled && props.onDelete && (
                <div>
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onDelete && props.onDelete();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              {props.customActions}
              {!props.isDisabled && props.dragHandleProps && (
                <div
                  className={classes.handleWrapper}
                  {...props.dragHandleProps}
                >
                  <DragHandleIcon fontSize="large" className={classes.handle} />
                </div>
              )}
            </div>
          )}
      </Card>
    );
  },
);
