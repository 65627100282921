import React from 'react';
import './App.css';
import {
  MuiThemeProvider,
  makeStyles,
  Theme,
  createStyles,
  Paper,
} from '@material-ui/core';
import DayJsUtils from '@date-io/dayjs';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Routes from './routes/Routes';
// import { ReactQueryDevtools } from 'react-query-devtools';

import Amplify from '@aws-amplify/core';
import { I18n } from '@aws-amplify/core';
import { environment } from './config/aws';
import { oAuthSignInButton } from '@aws-amplify/ui';
import {
  FormSection,
  SignInButton,
  SignInButtonContent,
  SectionHeader,
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React'; // should stop it complaining about missing unused deps

import Authenticator from 'aws-amplify-react/lib/Auth/Authenticator'; // should stop it complaining about missing unused deps
import SignIn from 'aws-amplify-react/lib/Auth/SignIn'; // should stop it complaining about missing unused deps
import ForgotPassword from 'aws-amplify-react/lib/Auth/ForgotPassword'; // should stop it complaining about missing unused deps
import RequireNewPassword from 'aws-amplify-react/lib/Auth/RequireNewPassword'; // should stop it complaining about missing unused deps
import VerifyContact from 'aws-amplify-react/lib/Auth/VerifyContact'; // should stop it complaining about missing unused deps
import ConfirmSignIn from 'aws-amplify-react/lib/Auth/ConfirmSignIn'; // should stop it complaining about missing unused deps
import ConfirmSignUp from 'aws-amplify-react/lib/Auth/ConfirmSignUp'; // should stop it complaining about missing unused deps
import TOTPSetup from 'aws-amplify-react/lib/Auth/TOTPSetup'; // should stop it complaining about missing unused deps
import { Loading } from 'aws-amplify-react/lib/Auth/Loading'; // should stop it complaining about missing unused deps
// import {  } from '@aws-amplify/ui';

import ThemeProvider from './contexts/ThemeContext';
import { ThemeContext } from './contexts/ThemeContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

import theme from './config/theme';
import { UsernameAttributes } from 'aws-amplify-react/lib-esm/Auth/common/types';
import Auth from '@aws-amplify/auth';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import InlineAlert from 'components/InlineAlert';
import { Logo } from 'components/Logo/Logo';
import { Box } from '@material-ui/core';

Amplify.configure(environment.amplify);
const dict = {
  en: {
    'Sign in to your account': 'Supplier Sign In',
    'Sign in with AWS': 'Sign in with Findel Account',
  },
};
I18n.putVocabularies(dict);

const authenticatorTheme = (darkMode?: boolean) => {
  const modeTheme = theme(darkMode);
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100%',
      flexGrow: 1,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    a: {
      color: modeTheme.palette.secondary.main,
    },
    button: {
      borderRadius: modeTheme.shape.borderRadius,
      backgroundColor: modeTheme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    toast: {
      backgroundColor: modeTheme.palette.error.main,
    },
    toastClose: {
      color: '#fff',
    },
    formContainer: {
      // marginTop: 8,
      margin: '0 auto',
    },
    oAuthSignInButton: {
      display: 'none',
    },
    oAuthSignInButton2: {
      backgroundImage:
        'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 85%, rgba(16,140,177,1) 85%, rgba(16,140,177,1) 90%, rgba(245,140,34,1) 90%, rgba(245,140,34,1) 95%, rgba(184,33,81,1) 95%)',
      border: '1px solid rgba(16,140,177,1)',
      color: 'rgba(16,140,177,1)',
      textTransform: 'uppercase',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    strike: {
      display: 'none',
    },
  };
};

const useSnackBarStyles = makeStyles((theme: Theme) => {
  const { success, info, warning, error } = theme.palette;

  return createStyles({
    success: {
      backgroundColor: success.main,
      // color: success.contrastText,
      color: '#fff',
    },
    info: {
      backgroundColor: info.main,
      // color: info.contrastText,
      color: '#fff',
    },
    warning: {
      backgroundColor: warning.main,
      // color: warning.contrastText,
      color: '#fff',
    },
    error: {
      backgroundColor: error.main,
      // color: error.contrastText,
      color: '#fff',
    },
  });
});

const queryCache = new QueryCache();

const checkEnvironmentVars = () => {
  const {
    REACT_APP_AUTH_IDENTITY_POOL,
    REACT_APP_AUTH_REGION,
    REACT_APP_AUTH_USER_POOL_ID,
    REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    REACT_APP_SYSTEMS_API_FUNCTION,
    REACT_APP_PRODUCTS_API_FUNCTION,
    REACT_APP_SUPPLIERS_API_FUNCTION,
  } = process.env;

  return Boolean(
    REACT_APP_AUTH_IDENTITY_POOL &&
      REACT_APP_AUTH_REGION &&
      REACT_APP_AUTH_USER_POOL_ID &&
      REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID &&
      REACT_APP_SYSTEMS_API_FUNCTION &&
      REACT_APP_PRODUCTS_API_FUNCTION &&
      REACT_APP_SUPPLIERS_API_FUNCTION,
  );
};

class App extends React.Component<{}, {}> {
  public render() {
    console.table(process.env);

    return (
      <React.Fragment>
        <ThemeProvider>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <CssBaseline />
            <AppInner />
          </ReactQueryCacheProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </React.Fragment>
    );
  }
}

function AppInner() {
  const sbClasses = useSnackBarStyles();

  const { darkMode } = React.useContext(ThemeContext);

  // const CustomContainer = ({ children }: any) => <section>{children}</section>;

  return (
    <MuiThemeProvider theme={theme(darkMode)}>
      <SnackbarProvider
        iconVariant={{
          error: () => null,
          success: () => null,
          info: () => null,
          warning: () => null,
        }}
        classes={{
          variantSuccess: sbClasses.success,
          variantError: sbClasses.error,
          variantWarning: sbClasses.warning,
          variantInfo: sbClasses.info,
        }}
        maxSnack={3}
        hidden={false}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        // children={() => <div></div>}
      >
        {!checkEnvironmentVars() ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Box p={3} clone>
              <Paper variant="elevation" elevation={4}>
                <Box maxWidth={160} mb={2} mx="auto">
                  <Logo />
                </Box>
                <InlineAlert
                  variant="error"
                  message="Application is missing required configuration"
                />
              </Paper>
            </Box>
          </Box>
        ) : (
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <Authenticator
              hideDefault={true}
              theme={authenticatorTheme(darkMode)}
              // container={CustomContainer}
              usernameAttributes={UsernameAttributes.EMAIL}
              // hide={[SignIn]}
              // authState="chooseLoginMethod"
            >
              <FindelSignIn />
              <SignIn />
              <ConfirmSignIn />
              <RequireNewPassword />
              <ConfirmSignUp />
              <VerifyContact />
              <ForgotPassword />
              <TOTPSetup />
              <Loading />
              <Routes />
            </Authenticator>
          </MuiPickersUtilsProvider>
        )}
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;

interface FindelSignInProps {
  [x: string]: any;
}

function FindelSignIn(props: FindelSignInProps) {
  const authConfig = environment.amplify.Auth as any;

  if (!(authConfig && authConfig.oauth)) {
    return null;
  }

  if (props.authState === 'signIn') {
    return (
      // <FormContainer theme={authenticatorTheme(props.darkMode)}>
      <FormSection theme={props.theme}>
        <SectionHeader>Findel User Sign In</SectionHeader>
        <SignInButton
          id={oAuthSignInButton}
          variant={'oAuthSignInButton2'}
          theme={props.theme}
          onClick={() =>
            Auth.federatedSignIn({ customProvider: 'AzureAD' })
              .then(result => {
                console.log('==========RESULT==========');
                console.log(result);
              })
              .catch(err => {
                console.log('==========ERROR==========');
                console.log(err);
              })
          }
        >
          <SignInButtonContent>Sign in with Findel</SignInButtonContent>
        </SignInButton>
        <SignIn />
      </FormSection>

      // </FormContainer>
    );
  }
  return null;
}
