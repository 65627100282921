import * as React from 'react';
import { Route, Link, LinkProps } from 'react-router-dom';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';

export interface NavItemProps extends MenuItemProps {
  to: string;
  activeOnlyWhenExact?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

// Pick<LinkProps, Exclude<keyof LinkProps, ''>>
// function NavLink(
//   props: Pick<LinkProps, 'to'> & Pick<MenuItemProps, 'selected'>,
// ) {
//   return <MenuItem {...{ component: Link, ...props } as any} />;
// }

// way to do this - https://material-ui.com/components/buttons/#third-party-routing-library

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link {...props} />,
);

const NavItem: React.SFC<NavItemProps> = ({
  to,
  activeOnlyWhenExact,
  children,
  onClick,
  //   ...props
}) => {
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <MenuItem
          tabIndex={0}
          component={AdapterLink}
          to={to}
          selected={Boolean(match)}
          onClick={onClick}
        >
          {children}
        </MenuItem>
      )}
    />
  );
};

export default NavItem;
