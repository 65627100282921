import * as React from 'react';

import { Image, Video } from 'api/products/model/models';
import { makeStyles, Theme, createStyles, IconButton } from '@material-ui/core';
import IconArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import 'react-responsive-modal/styles.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import Modal from 'react-responsive-modal';

import ImageGallery from 'react-image-gallery';

import Player from 'react-player';
import { imageUrlEncoder } from 'helpers/imageUrlEncoder';
import { ExpandNotice } from './MediaPreview/ExpandNotice';
import { PreviewCarouselImage } from './MediaPreview/PreviewCarouselImage';
import { VideoThumbnail } from './MediaPreview/VideoThumbnail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imagePlaceholder: {
      backgroundColor: theme.palette.grey[100],
      paddingTop: '100%',
      position: 'relative',
      border: `1px solid ${theme.palette.grey[200]}`,
      cursor: 'pointer',
    },
    gallery: {
      '& .image-gallery-thumbnail.active': {
        borderColor: theme.palette.secondary.main,
      },
      '& .image-gallery-slide': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
      },
      '& .image-gallery-thumbnails-wrapper.left': {
        marginLeft: 0,
      },
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },

    modalImage: {
      position: 'absolute',
      backgroundColor: 'rgb(247, 247, 247)',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    modalVideoWrapper: {
      position: 'relative',
      display: 'block',
      width: '100%',
      padding: 0,
      overflow: 'hidden',
      '&:before': {
        paddingTop: '56.25%',
        display: 'block',
        content: '""',
      },
    },
    modalVideo: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
    },
    productImage: {
      width: '100%',
      cursor: 'zoom-in',
    },
    iconSpacer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      zIndex: 6,
      right: 4,
      '&:first-child': {
        left: 4,
        right: 'auto',
      },
      '&:last-child': {},
    },
    icon: {
      background: 'rgba(255,255,255,0.8)',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
    },
  }),
);

interface CarouselItem {
  url: string;
  type: 'video' | 'image';
}

export interface MediaPreviewProps {
  images?: Image[];
  videos?: Video[];
  thumbnailPosition?: 'top' | 'right' | 'bottom' | 'left';
}

export function MediaPreview({
  images = [],
  videos = [],
  thumbnailPosition = 'bottom',
}: MediaPreviewProps) {
  const classes = useStyles();

  const [slideIndex, setSlideIndex] = React.useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);

  // const hasImages = Boolean(images && images.length > 0);
  // const hasVideos = Boolean(videos && videos.length > 0);

  const heroImage = images && images.length > 0 ? images[0] : null;

  function toggleLightbox() {
    setIsLightboxOpen(prev => !prev);
  }

  const items: CarouselItem[] = [
    {
      url: heroImage?.filekey || '',
      type: 'image',
    },
    ...videos.map(video => {
      const item: CarouselItem = {
        url: video.url || '',
        type: 'video',
      };
      return item;
    }),
    ...images
      .filter((img, index) => index !== 0)
      .map(image => {
        const item: CarouselItem = {
          url: image.filekey || '',
          type: 'image',
        };
        return item;
      }),
    // .filter(item => item.url !== primaryImageUrl), // may need to add filter to remove blank URLS
  ];

  const pageGallery = (
    <ImageGallery
      disableKeyDown={isLightboxOpen}
      startIndex={slideIndex}
      onSlide={currentIndex => {
        setSlideIndex(currentIndex);
      }}
      showNav={true}
      showFullscreenButton={false}
      showBullets={false}
      showPlayButton={false}
      onClick={toggleLightbox}
      items={items.map(item => ({
        original: item.url,
        thumbnail: item.url,
      }))}
      showThumbnails={items.length > 1}
      thumbnailPosition={thumbnailPosition}
      renderThumbInner={item => {
        // add a check for video
        if (
          item.original &&
          (item.original.includes('.vimeo.') ||
            item.original.includes('.youtube.'))
        ) {
          return <VideoThumbnail videoUrl={item.original} isThumb />;
        }

        return (
          <div>
            <PreviewCarouselImage
              className={classes.productImage}
              imageKey={item.original}
            />
          </div>
        );
      }}
      renderItem={item => {
        // add a check for video
        if (
          item.original &&
          (item.original.includes('.vimeo.') ||
            item.original.includes('.youtube.'))
        ) {
          return (
            <div>
              <VideoThumbnail videoUrl={item.original} />
              <ExpandNotice />
            </div>
          );
        }

        return (
          <div>
            <PreviewCarouselImage
              className={classes.productImage}
              imageKey={item.original}
            />
            <ExpandNotice />
          </div>
        );
      }}
      renderLeftNav={(
        onClick: React.MouseEventHandler<HTMLElement>,
        disabled?: boolean,
      ) => (
        <div className={classes.iconSpacer}>
          <IconButton
            className={classes.icon}
            color="secondary"
            disabled={disabled}
            onClick={onClick}
          >
            <IconArrowLeft />
          </IconButton>
        </div>
      )}
      renderRightNav={(
        onClick: React.MouseEventHandler<HTMLElement>,
        disabled?: boolean,
      ) => (
        <div className={classes.iconSpacer}>
          <IconButton
            className={classes.icon}
            color="secondary"
            disabled={disabled}
            onClick={onClick}
          >
            <IconArrowRight />
          </IconButton>
        </div>
      )}
    />
  );

  return (
    <div>
      <div className={classes.gallery}>{pageGallery}</div>
      <Modal
        open={isLightboxOpen}
        onClose={toggleLightbox}
        center
        showCloseIcon={false}
        // classNames={{
        //   modal: 'test'
        // }}
        styles={{
          modal: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: '94vmin',
            padding: 0,
          },
        }}
      >
        <ImageGallery
          startIndex={slideIndex}
          onSlide={currentIndex => {
            setSlideIndex(currentIndex);
          }}
          showNav={true}
          showFullscreenButton={false}
          showBullets={false}
          showPlayButton={false}
          onClick={toggleLightbox}
          showThumbnails={false}
          items={items.map(item => ({
            original: item.url,
          }))}
          renderItem={item => {
            // add a check for video
            if (
              item.original &&
              (item.original.includes('.vimeo.') ||
                item.original.includes('.youtube.') ||
                item.original.includes('.youtu.be'))
            ) {
              return (
                <div className={classes.modalVideoWrapper}>
                  <Player
                    className={classes.modalVideo}
                    url={item.original}
                    height="auto"
                    width="100%"
                    controls={true}
                  />
                </div>
              );
            }

            const imageSrc = imageUrlEncoder({
              format: 'jpeg',
              key: item.original || '',
              resize: {
                height: 1000,
                width: 1000,
                fit: 'contain',
                background: {
                  alpha: 1,
                  r: 255,
                  g: 255,
                  b: 255,
                },
              },
            });

            return (
              <div style={{ paddingTop: '100%', position: 'relative' }}>
                <img src={imageSrc} alt="" className={classes.modalImage} />
              </div>
            );
          }}
          renderThumbInner={() => null}
        />
      </Modal>
    </div>
  );
}
