import * as React from 'react';
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core';
import Icon from '@material-ui/icons/LocalShipping';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      // padding: theme.spacing(2), // shouldn't be needed now
      backgroundColor: theme.palette.grey['200'],
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1),
      transform: 'scaleX(-1)',
    },
  }),
);

export interface DirectDeliveryBannerProps {
  leadTimeDays?: number;
}

export function DirectDeliveryBanner({
  leadTimeDays,
}: DirectDeliveryBannerProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Icon className={classes.icon} /> Direct Delivery - up to{' '}
      {leadTimeDays || '# weeks'}
      <Typography
        variant="subtitle1"
        color="textPrimary"
        component="p"
      ></Typography>
    </div>
  );
}
