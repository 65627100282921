import * as React from 'react';
import { Route } from 'react-router-dom';
import InlineLink from '../InlineLink';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    crumb: {
      marginRight: theme.spacing(1),
      textDecoration: 'none',
      display: 'block',
    },
    link: {
      color: theme.palette.secondary.main,
      '&:after': {
        textDecoration: 'none',
        content: '"/"',
        display: 'inline-block',
        color: theme.palette.grey['400'],
        marginLeft: theme.spacing(1),
      },
    },
    breadcrumbOnly: {
      color: theme.palette.grey['400'],
    },
  });

interface BreadcrumbProps extends WithStyles<typeof styles> {
  to: string;
  activeOnlyWhenExact?: boolean;
  children: React.ReactNode;
  breadcrumbOnly?: boolean;
  breadcrumbLinkDisabled?: boolean;
}

const Breadcrumb: React.SFC<BreadcrumbProps> = ({
  to,
  activeOnlyWhenExact,
  children,
  classes,
  breadcrumbOnly,
  breadcrumbLinkDisabled,
}) => {
  if (children) {
    return (
      <Route
        path={to}
        exact={false}
        children={({ match }) => {
          if (match && Boolean(match)) {
            if (match.isExact) {
              return (
                <span className={[classes.crumb].join(' ')} key={match.url}>
                  {children}
                </span>
              );
            }

            if (breadcrumbLinkDisabled) {
              return (
                <span
                  className={[
                    classes.crumb,
                    classes.link,
                    classes.breadcrumbOnly,
                  ].join(' ')}
                >
                  {children}
                </span>
              );
            }

            return (
              <span key={to} data-cy="breadcrumb">
                {' '}
                <InlineLink
                  data-cy="breadcrumb-link"
                  className={[classes.crumb, classes.link].join(' ')}
                  color="secondary"
                  to={match.url}
                >
                  {children}
                </InlineLink>
              </span>
            );
          }
          return null;
        }}
      />
    );
  }
  return null;
};

export default withStyles(styles)(Breadcrumb);
