type ImageFormat = 'auto' | 'jpeg' | 'png' | 'webp';

export type ImageUrlParams = {
  key: string;
  format?: ImageFormat;
  quality?: number;
  resize?: {
    fit: 'outside' | 'inside' | 'cover' | 'contain' | 'fill';
    position?:
      | 'bottom'
      | 'left bottom'
      | 'left top'
      | 'left'
      | 'right bottom'
      | 'right top'
      | 'right'
      | 'top';
    strategy?: 'entropy' | 'attention';
    height: number;
    width: number;
    background?: {
      r: number;
      g: number;
      b: number;
      alpha: number;
    };
  };
  grayscale?: boolean;
  flip?: boolean;
  flop?: boolean;
  negate?: boolean;
  flatten?: boolean;
  normalise?: boolean;
  rotate?: true | number;
  // smartCrop?: {
  //   focusIndex: number;
  //   padding?: number;
  // };
};

export function imageUrlEncoder({
  key,
  format = 'auto',
  quality = 80,
  ...edits
}: ImageUrlParams): string {
  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  const s3Bucket = process.env.REACT_APP_PRODUCT_MEDIA_BUCKET;

  // configs
  const webp = {
    webp: {
      quality,
    },
  };
  const jpeg = {
    jpeg: {
      quality,
      progressive: true,
    },
  };
  const png = {
    png: {
      quality,
    },
  };

  function getFormatOptions(format: ImageFormat) {
    switch (format) {
      case 'auto':
        // todo: hard to check webp support
        // inline, may need to do on
        // app start
        // if (true) {
        //   return webp;
        // }
        const lowerKey = key.toLowerCase();
        if (lowerKey.match(/(jpg|jpeg)$/)) return jpeg;
        if (lowerKey.match(/png$/)) return png;
        if (lowerKey.match(/webp$/)) return webp;
        return {};
      case 'jpeg':
        return jpeg;
      case 'png':
        return png;
      case 'webp':
        return webp;
    }
  }

  const imageRequest = JSON.stringify({
    bucket: s3Bucket,
    key: key,
    edits: {
      ...edits,
      ...getFormatOptions(format),
    },
  });

  const url = `${cloudFrontUrl}/${btoa(imageRequest)}`;

  return url;
}
