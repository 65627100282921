import * as React from 'react';
// import * as PropTypes from 'prop-types';
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// import { mailFolderListItems, otherMailFolderListItems } from './tileData';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      // height: 430,
      zIndex: 1,
      // overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    paper: {
      maxWidth: 400,
      margin: theme.spacing(1),
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  });

interface BaseLayoutProps extends WithStyles<typeof styles> {
  // theme: Theme;
  hasPaper?: boolean;
  children?: React.ReactNode;
}

interface BaseLayoutState {
  mobileOpen: boolean;
}

class BaseLayout extends React.Component<BaseLayoutProps, BaseLayoutState> {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const {
      classes,
      // theme,
      hasPaper,
    } = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.content}>
          {!hasPaper && this.props.children}
          {hasPaper && (
            <Paper className={classes.paper}>{this.props.children}</Paper>
          )}
        </main>
      </div>
    );
  }
}

// BaseLayout.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

export default withStyles(styles, { withTheme: true })(BaseLayout);
