import * as React from 'react';
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';

// import { SnackbarProps } from '@material-ui/core/Snackbar';

// todo: tie these into the theme later on
const styles = (theme: Theme) => {
  const { success, info, warning, error } = theme.palette;

  return createStyles({
    base: {
      flexGrow: 1,
    },
    success: {
      backgroundColor: success.main,
      color: success.contrastText,
    },
    info: {
      backgroundColor: info.main,
      color: info.contrastText,
    },
    warning: {
      backgroundColor: warning.main,
      color: warning.contrastText,
    },
    error: {
      backgroundColor: error.main,
      color: error.contrastText,
    },
  });
};

interface InlineAlertProps extends WithStyles<typeof styles> {
  className?: string;
  action?: React.ReactNode;
  message: React.ReactNode;
  variant: 'success' | 'info' | 'warning' | 'error';
  // snackbarProps?: SnackbarProps;
}

const InlineAlert: React.SFC<InlineAlertProps> = ({
  variant,
  message,
  classes,
  action,
  className,
}) => {
  const classNames: string[] = [];

  if (className) {
    classNames.push(className);
  }
  classNames.push(classes.base);
  classNames.push(classes[variant]);

  return (
    <SnackbarContent
      // component={() => <div />}
      // {...snackbarProps}

      className={classNames.join(' ')}
      message={message}
      action={action}
    />
  );
};

export default withStyles(styles)(InlineAlert);
