import * as React from 'react';
import { AuthConsumer } from '../../contexts/AuthContext';
import AsyncComponentLoader from '../../components/AsyncComponent/AsyncComponentLoader';
import { Typography } from '@material-ui/core';
import { FormHeading } from 'components/FormHeading';

// interface UnauthorisedProps {}

const Unauthorised: React.SFC<{}> = props => {
  return (
    <AuthConsumer>
      {({ isLoading }) => {
        if (isLoading) {
          return <AsyncComponentLoader showLoader={true} />;
        }
        return (
          <section>
            <FormHeading>Access Denied</FormHeading>
            <Typography color="textPrimary" variant="body1">
              If you believe you should have access to this information please
              contact an administrator.
            </Typography>
          </section>
        );
      }}
    </AuthConsumer>
  );
};

export default Unauthorised;
