import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  // const isDark = theme.palette.type === 'dark';

  return createStyles({
    root: {},
    img: {
      height: 'auto',
      width: 'auto',
      '& #logo-text path': {
        fill: theme.palette.text.secondary,
      },
      // '& #swatch-top': {
      //   fill: isDark ? '#ffffff' : theme.palette.primary.main,
      // },
      // '& #swatch-middle': {
      //   fill: isDark ? '#ffffff' : theme.palette.secondary.main,
      // },
      // '& #swatch-bottom': {
      //   fill: isDark ? '#ffffff' : theme.palette.error.main,
      // },
    },
  });
});

export interface LogoProps {}

export function Logo(props: LogoProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <svg
        className={classes.img}
        viewBox="0 0 185 43"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <path id="L9-3" fill="none" d="M-13.361-14.341h220v71h-220z" />
        <g id="Logo-10">
          <g id="logo-text">
            <path
              d="M.36-.7c.038 0 .071.008.1.024a.17.17 0 01.066.067.214.214 0 01.023.1.212.212 0 01-.024.101.168.168 0 01-.066.071.187.187 0 01-.099.026H.138l.004-.007v.298a.02.02 0 01-.006.014A.024.024 0 01.121 0C.114 0 .109-.002.106-.006A.02.02 0 01.1-.02v-.66a.02.02 0 01.006-.014A.02.02 0 01.12-.7h.24zm0 .349a.152.152 0 00.079-.02.146.146 0 00.05-.057.179.179 0 00.018-.081.169.169 0 00-.018-.08.139.139 0 00-.05-.052A.152.152 0 00.36-.66H.139l.003-.005v.317L.138-.351H.36z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(-3.123 33.694) scale(42.86892)"
            />
            <path
              d="M.103 0a.02.02 0 01-.015-.006.024.024 0 01-.005-.014v-.459a.02.02 0 01.006-.014.02.02 0 01.014-.006c.006 0 .011.002.014.006a.02.02 0 01.006.014v.169l-.017.026a.247.247 0 01.117-.209.16.16 0 01.116-.018c.011.003.016.009.016.019a.025.025 0 01-.005.015.016.016 0 01-.013.006.03.03 0 01-.014-.005.057.057 0 00-.025-.004.118.118 0 00-.063.019.268.268 0 00-.097.119.194.194 0 00-.015.072v.25a.02.02 0 01-.006.014A.02.02 0 01.103 0z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(22.34 33.694) scale(42.86892)"
            />
            <path
              d="M.541-.252a.285.285 0 01-.032.134.248.248 0 01-.088.094.242.242 0 01-.126.034.234.234 0 01-.126-.035.252.252 0 01-.089-.094.278.278 0 01-.032-.133c0-.05.011-.095.032-.135a.249.249 0 01.215-.128.239.239 0 01.214.128c.021.04.032.085.032.135zm-.04 0a.238.238 0 00-.027-.115.202.202 0 00-.072-.081.202.202 0 00-.107-.029.204.204 0 00-.18.11.238.238 0 00-.027.115.219.219 0 00.101.195.2.2 0 00.106.029c.04 0 .076-.01.107-.029a.216.216 0 00.073-.08.253.253 0 00.026-.115z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(37.174 33.694) scale(42.86892)"
            />
            <path
              d="M.379-.7A.258.258 0 01.6-.594a.33.33 0 01.053.112.478.478 0 01-.014.308.272.272 0 01-.096.127A.272.272 0 01.379 0H.124a.029.029 0 01-.021-.009.029.029 0 01-.009-.021v-.64c0-.008.003-.015.009-.021A.029.029 0 01.124-.7h.255zm-.01.641a.223.223 0 00.137-.039.23.23 0 00.077-.105.436.436 0 00.011-.257.282.282 0 00-.042-.093.2.2 0 00-.074-.064.234.234 0 00-.109-.024H.15L.157-.65v.601l-.006-.01h.218z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(62.423 33.694) scale(42.86892)"
            />
            <path
              d="M.137-.03a.029.029 0 01-.009.021A.029.029 0 01.107 0a.026.026 0 01-.021-.009.029.029 0 01-.009-.021v-.46c0-.008.003-.015.009-.021A.026.026 0 01.107-.52a.03.03 0 01.022.009.032.032 0 01.008.021v.46zm-.03-.557c-.014 0-.025-.003-.032-.01-.007-.006-.01-.015-.01-.027v-.01c0-.012.004-.021.011-.027a.047.047 0 01.032-.01c.013 0 .022.003.029.01a.034.034 0 01.011.027v.01a.034.034 0 01-.011.027c-.007.007-.017.01-.03.01z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(93.246 33.694) scale(42.86892)"
            />
            <path
              d="M.288-.523a.24.24 0 01.148.055.219.219 0 01.045.053.098.098 0 01.016.052L.481-.387V-.48c0-.009.003-.016.008-.022a.03.03 0 01.022-.009c.009 0 .016.003.021.009a.03.03 0 01.009.022v.433a.26.26 0 01-.033.135.212.212 0 01-.09.083A.277.277 0 01.29.2.332.332 0 01.15.168C.132.159.122.149.119.139A.027.027 0 01.112.121.024.024 0 01.121.107C.127.102.134.102.141.105a.453.453 0 00.101.034.263.263 0 00.049.005c.06 0 .107-.017.14-.052a.186.186 0 00.05-.135v-.096l.011.003a.167.167 0 01-.03.063.214.214 0 01-.049.046.23.23 0 01-.124.037.233.233 0 01-.212-.13.291.291 0 01-.032-.137c0-.051.011-.096.032-.136a.235.235 0 01.211-.13zm.006.056a.175.175 0 00-.098.028.19.19 0 00-.068.075.231.231 0 00-.024.107c0 .04.008.076.024.108a.2.2 0 00.068.076.183.183 0 00.098.027.18.18 0 00.166-.101.232.232 0 00.025-.11.225.225 0 00-.025-.109.18.18 0 00-.166-.101z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(102.42 33.694) scale(42.86892)"
            />
            <path
              d="M.137-.03a.029.029 0 01-.009.021A.029.029 0 01.107 0a.026.026 0 01-.021-.009.029.029 0 01-.009-.021v-.46c0-.008.003-.015.009-.021A.026.026 0 01.107-.52a.03.03 0 01.022.009.032.032 0 01.008.021v.46zm-.03-.557c-.014 0-.025-.003-.032-.01-.007-.006-.01-.015-.01-.027v-.01c0-.012.004-.021.011-.027a.047.047 0 01.032-.01c.013 0 .022.003.029.01a.034.034 0 01.011.027v.01a.034.034 0 01-.011.027c-.007.007-.017.01-.03.01z"
              fill="#767373"
              fillRule="nonzero"
              transform="translate(128.999 33.694) scale(42.86892)"
            />
          </g>
          <path
            id="swatch-bottom"
            d="M175.627 24.132l7.778 3.473-20.034 8.946-20.034-8.946 7.777-3.473 12.257 5.473 12.256-5.473z"
            fill="#bc1f54"
          />
          <path
            id="swatch-top"
            d="M183.405 9.713L163.371.767l-20.034 8.946 20.034 8.946 20.034-8.946z"
            fill="#108cb1"
          />
          <path
            id="swatch-middle"
            d="M175.627 15.186l7.778 3.473-20.034 8.946-20.034-8.946 7.777-3.473 12.257 5.473 12.256-5.473z"
            fill="#f78f21"
          />
        </g>
      </svg>
    </div>
  );
}
