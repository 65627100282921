import asyncComponent from '../components/AsyncComponent';
import { ApiPath } from 'types/endpoints';

// dashboard
const AsyncDashboard = asyncComponent(
  () => import('../containers/Dashboard/Dashboard'),
  true,
);

// NPI
const AsyncNpiList = asyncComponent(
  () => import('../containers/Npi/NpiListPage'),
  true,
);

const AsyncNpi = asyncComponent(
  () => import('../containers/Npi/NpiPage'),
  true,
);

const AsyncPimProductGroupPage = asyncComponent(
  () => import('../containers/Pim/PimProductGroupPage'),
  true,
);

// const AsyncNpiAttributePage = asyncComponent(
//   () => import('../containers/Npi/NpiAttributePage'),
//   true,
// );
// const AsyncNpiGroup = asyncComponent(() => import('../containers/Npi/NpiGroupPage'), true);
// const AsyncNpiProduct = asyncComponent(() => import('../containers/Npi/NpiProductPage'), true);

// Roles
const AsyncRoles = asyncComponent(
  () => import('../containers/Roles/Roles'),
  true,
);

const AsyncRoleDetails = asyncComponent(
  () => import('../containers/Roles/RoleDetail'),
  true,
);

// Suppliers
const AsyncSuppliers = asyncComponent(
  () => import('../containers/Suppliers/Suppliers'),
  true,
);

const AsyncSupplierDetails = asyncComponent(
  () => import('../containers/Suppliers/Supplier'),
  true,
);

// const AsyncSupplierMerchandisers = asyncComponent(() => import('../containers/Suppliers/SupplierMerchandisersPage'), true);
// const AsyncSupplierAddress = asyncComponent(() => import('../containers/Suppliers/SupplierAddressPage'), true);

// Users
const AsyncFindelUsers = asyncComponent(
  () => import('../containers/Users/Users'),
  true,
);

const AsyncUserDetails = asyncComponent(
  () => import('../containers/Users/UserDetails'),
  true,
);

const AsyncSupplierUsers = asyncComponent(
  () => import('../containers/SupplierUsers/SupplierUsers'),
  true,
);

const AsyncSupplierUserDetails = asyncComponent(
  () => import('../containers/SupplierUsers/SupplierUser'),
  true,
);

// Profile
const AsyncProfile = asyncComponent(
  () => import('../containers/Profile/Profile'),
  true,
);

// System Config
const AsyncProductConfig = asyncComponent(
  () => import('../containers/ProductConfig/ProductConfigPage'),
  true,
);
const AsyncRoundelConfig = asyncComponent(
  () => import('../containers/ProductConfig/RoundelConfigPage'),
  true,
);

const AsyncProductConfigListPage = asyncComponent(
  () => import('../containers/ProductConfig/ProductConfigListPage'),
  true,
);

const AsyncProductConfigDetailPage = asyncComponent(
  () => import('../containers/ProductConfig/ProductConfigDetailPage'),
  true,
);

const AsyncProductConfigAttributeList = asyncComponent(
  () => import('../containers/ProductConfig/ProductConfigAttributeList'),
  true,
);

const AsyncProductConfigAttributeForm = asyncComponent(
  () => import('../containers/ProductConfig/ProductConfigAttributeForm'),
  true,
);

const AsyncProductOffers = asyncComponent(
  () => import('../containers/Offers/ProductOffers'),
  true,
);
const AsyncProductOfferXForY = asyncComponent(
  () => import('../containers/Offers/ProductOfferXForY'),
  true,
);

//ProductOfferDiscountProduct

const AsyncProductOfferFreeGift = asyncComponent(
  () => import('../containers/Offers/ProductOfferFreeGift'),
  true,
);

const AsyncProductConfigAttributeDetailPage = asyncComponent(
  () => import('../containers/ProductConfig/ProductConfigAttributeDetailPage'),
  true,
);

const AsyncSwaggerPage = asyncComponent(
  () => import('../containers/SwaggerPage'),
  true,
);

const AsyncPlayground = asyncComponent(
  () => import('../containers/Playground/Playground'),
  true,
);
//ProductImageProcessLog
const AsyncProductImageProcessLog = asyncComponent(
  () => import('../containers/Reports/ProductImageProcessLog'),
  true,
);

// const AsyncProductPromotions = asyncComponent(
//   () => import('containers/Pim/PimPromotions'),
//   true,
// );

// const AsyncPimPromotionDetail = asyncComponent(
//   () => import('containers/Pim/PimPromotionDetail'),
//   true,
// );

// const AsyncProductConfigCountry = asyncComponent(() => import('../containers/ProductConfig/CountryPage'), true);

export type RouteList = RouteListItem[];

interface RouteListItem {
  breadcrumb?: string;
  component?: React.ReactNode; // maybe leave this blank for breadcrumb only route?

  path: string;
  requiredRole?: ApiPath;
  routes?: RouteList;
  exact?: boolean;
  // exact?: boolean;
  breadcrumbOnly?: boolean;
  breadcrumbLinkDisabled?: boolean;
}

/**
 * Note: the order is important
 * for the breacrumbs to work
 */
const privateRouteList: RouteList = [
  // Home/Dashboard
  {
    breadcrumb: 'Home',
    component: AsyncDashboard,
    path: '/',
    requiredRole: 'SystemUserInfo',
  },
  // Home/Auth
  {
    breadcrumb: 'Authentication',
    path: '/auth',
    breadcrumbOnly: true,
    // requiredRole: 'SystemUserInfo',
  },
  // NPIS
  {
    breadcrumb: 'NPIs',
    component: AsyncNpiList,
    path: '/npi',
    requiredRole: 'ProductGroupDetails', // TODO: this needs a better role
  },
  // {
  //   path: '/npi/:id/attributes/:attributeId',
  //   component: AsyncNpiAttributePage,
  // },
  {
    breadcrumb: 'NPI',
    component: AsyncNpi,
    path: '/npi/:id',
    exact: false,
    requiredRole: 'ProductGroup',
  },
  {
    breadcrumb: 'Variants',
    path: '/npi/:id/variants',
    breadcrumbOnly: true,
  },
  {
    // this is a hack to get around the
    // routelist being looped through
    // for breadcrumb generation
    breadcrumb: 'Variant',
    path: '/npi/:id/variants/:attributeId',
    breadcrumbOnly: true,
  },
  {
    // this is a hack to get around the
    // routelist being looped through
    // for breadcrumb generation
    breadcrumb: 'Products',
    path: '/npi/:id/products',
    breadcrumbOnly: true,
  },
  {
    // this is a hack to get around the
    // routelist being looped through
    // for breadcrumb generation
    breadcrumb: 'Details',
    path: '/npi/:id/products/details',
    breadcrumbOnly: true,
  },
  // {
  //   breadcrumb: 'Media',
  //   path: '/npi/:id/products/media',
  //   breadcrumbOnly: true,
  //   // exact: true,
  // },
  {
    breadcrumb: 'Images',
    path: '/npi/:id/products/media/images',
    breadcrumbOnly: true,
    // exact: true,
  },
  {
    breadcrumb: 'Content',
    path: '/npi/:id/products/media/content',
    breadcrumbOnly: true,
    // exact: true,
  },
  {
    breadcrumb: 'Documents',
    path: '/npi/:id/products/media/documents',
    breadcrumbOnly: true,
    // exact: true,
  },
  {
    breadcrumb: 'Test Certificates',
    path: '/npi/:id/products/media/certificates',
    breadcrumbOnly: true,
    // exact: true,
  },
  {
    breadcrumb: 'Videos',
    path: '/npi/:id/products/media/videos',
    breadcrumbOnly: true,
    // exact: true,
  },
  {
    breadcrumb: 'Videos',
    path: '/npi/:id/products/media/videos/:videoId',
    breadcrumbOnly: true,
    // exact: true,
  },

  {
    breadcrumb: 'Product',
    path: '/npi/:id/products/:productId(\\d+)',
    breadcrumbOnly: true,
    // exact: true,
  },
  {
    breadcrumb: 'Buyer',
    path: '/npi/:id/products/:productId(\\d+)/buyer',
    breadcrumbOnly: true,
  },
  {
    breadcrumb: 'Buyer',
    path: '/pim/:id/extended-products/:productId(\\d+)/buyer',
    breadcrumbOnly: true,
  },
  {
    breadcrumb: 'Supplier',
    path: '/npi/:id/products/:productId(\\d+)/supplier',
    breadcrumbOnly: true,
  },
  {
    breadcrumb: 'QA',
    path: '/npi/:id/qa',
    breadcrumbOnly: true,
  },
  {
    breadcrumb: 'Merchandisers',
    path: '/npi/:id/merchandiser',
    breadcrumbOnly: true,
  },
  {
    breadcrumb: 'Finish Setup',
    path: '/npi/:id/manage',
    breadcrumbOnly: true,
  },
  // {
  //     breadcrumb: 'Group',
  //     component: AsyncNpiGroup,
  //     path: '/npi/:npiId/:groupId',
  //     // requiredRole: 'GetNpis' // needs an available role
  // },
  // {
  //     breadcrumb: 'Product',
  //     component: AsyncNpiProduct,
  //     path: '/npi/:npiId/:groupId/:productId',
  //     // requiredRole: 'GetNpis' // needs an available role
  // },
  // Roles
  {
    breadcrumb: 'Roles',
    component: AsyncRoles,
    path: '/roles',
    requiredRole: 'SystemRoles',
  },
  {
    breadcrumb: 'Role',
    component: AsyncRoleDetails,
    path: '/roles/:id',
    requiredRole: 'GetSystemRolePermissionDetail',
  },
  // Suppliers
  {
    breadcrumb: 'Suppliers',
    component: AsyncSuppliers,
    path: '/suppliers',
    requiredRole: 'InsertSystemUserSupplier', // work on assumption that if they can create a user they should be able to see supplier list
  },
  {
    breadcrumb: 'Supplier',
    component: AsyncSupplierDetails,
    path: '/suppliers/:supplierId',
    exact: false,
    requiredRole: 'InsertSystemUserSupplier',
  },
  {
    breadcrumb: 'Merchandisers',
    component: AsyncSupplierDetails,
    path: '/suppliers/:supplierId/merchandisers',
    exact: false,
    breadcrumbOnly: true,
    // requiredRole: 'GetSuppliers',
  },
  {
    breadcrumb: 'Addresses',
    component: AsyncSupplierDetails,
    path: '/suppliers/:supplierId/addresses',
    breadcrumbOnly: true,

    // exact: false,
  },
  {
    breadcrumb: 'Address',
    component: AsyncSupplierDetails,
    path: '/suppliers/:supplierId/addresses/:addressId',
    breadcrumbOnly: true,
    // exact: false,
  },
  {
    breadcrumb: 'Contacts',
    component: AsyncSupplierDetails,
    path: '/suppliers/:supplierId/addresses/:addressId/contacts',
    breadcrumbOnly: true,
    // exact: false,
  },
  {
    breadcrumb: 'Contact',
    component: AsyncSupplierDetails,
    path: '/suppliers/:supplierId/addresses/:addressId/contacts/:contactId',
    breadcrumbOnly: true,
    // exact: false,
  },
  {
    breadcrumb: 'Findel Users',
    component: AsyncFindelUsers,
    path: '/users/findel',
    requiredRole: 'InsertSystemUserFindel',
  },
  {
    breadcrumb: 'Findel User',
    component: AsyncUserDetails,
    path: '/users/findel/:id',
    requiredRole: 'InsertSystemUserFindel',
  },
  {
    breadcrumb: 'Supplier Users',
    component: AsyncSupplierUsers,
    path: '/users/supplier',
    requiredRole: 'InsertSystemUserSupplier',
  },
  {
    breadcrumb: 'Supplier User',
    component: AsyncSupplierUserDetails,
    path: '/users/supplier/:id',
    requiredRole: 'InsertSystemUserSupplier',
  },
  {
    breadcrumb: 'Profile',
    component: AsyncProfile,
    path: '/profile',
    requiredRole: 'SystemUserInfo',
  },
  // Product Configuration
  {
    breadcrumb: 'Product Config',
    component: AsyncProductConfig,
    path: '/product-config',
    requiredRole: 'InsertSystemUserSupplier',
  },

  {
    breadcrumb: 'Roundel Administration',
    component: AsyncRoundelConfig,
    path: '/product-config/roundels',
    requiredRole: 'GetProductPromotions',
  },
  {
    breadcrumb: 'List',
    component: AsyncProductConfigListPage,
    path: '/product-config/:areaName',
    requiredRole: 'InsertSystemUserSupplier',
  },
  {
    breadcrumb: 'Detail',
    component: AsyncProductConfigDetailPage,
    path: '/product-config/:areaName/:itemId',
    requiredRole: 'InsertSystemUserSupplier',
  },
  {
    breadcrumb: 'Manage Attributes',
    component: AsyncProductConfigAttributeList,
    path: '/manage-attributes',
    requiredRole: 'InsertProductAttribute',
  },
  {
    breadcrumb: 'Category',
    component: AsyncProductConfigAttributeList,
    path: '/manage-attributes/:categoryId',
    requiredRole: 'InsertProductAttribute',
  },
  {
    breadcrumb: 'Attribute',
    component: AsyncProductConfigAttributeForm,
    path: '/manage-attributes/:categoryId/:productAttributeId',
    requiredRole: 'InsertProductAttribute',
  },
  {
    breadcrumb: 'Detail',
    component: AsyncProductConfigAttributeDetailPage,
    path:
      '/manage-attributes/:categoryId/:productAttributeId/:productAttributeValueId',
    requiredRole: 'InsertProductAttributeValueDetails',
  },
  /**
   * Offers
   */
  {
    breadcrumb: 'Offers',
    component: AsyncProductOffers,
    path: '/offers',
    requiredRole: 'ProductOfferExtraFree',
  },
  {
    breadcrumb: 'Extra',
    component: AsyncProductOfferXForY,
    path: '/offers/XForY/:productOfferId',
    requiredRole: 'InsertOfferExtraFree',
  },
  {
    breadcrumb: 'Extra',
    component: AsyncProductOfferFreeGift,
    path: '/offers/freegift/:productOfferId',
    requiredRole: 'InsertOfferFreeProduct',
  },
  // {
  //   breadcrumb: 'Percentage discount',
  //   component: AsyncProductOfferDiscountProduct,
  //   path: '/offers/percentagediscount/:productOfferId',
  //   requiredRole: 'InsertProductDiscountOffer',
  // },

  //AsyncProductOfferDiscountProduct

  /**
   * Reports
   */
  {
    breadcrumb: 'Reports',
    // component: asyncComponent(
    //   () => import('../containers/Reports/PimDataQualityReport'),
    //   true,
    // ),
    breadcrumbOnly: true,
    breadcrumbLinkDisabled: true,
    path: '/reports',
    // requiredRole: 'ProductGroupPimContentDetail',
  },
  {
    breadcrumb: 'Data Quality',
    component: asyncComponent(
      () => import('../containers/Reports/PimDataQualityReport'),
      true,
    ),
    path: '/reports/data-quality-report',
    requiredRole: 'GetProductGroupQualityScoreReport',
  },
  {
    breadcrumb: 'Image Processing',
    component: AsyncProductImageProcessLog,
    path: '/reports/image-process-log',
    requiredRole: 'ProductImageProcessLogDetails',
  },
  {
    breadcrumb: 'Product Data Extract',
    component: asyncComponent(
      () => import('../containers/Reports/ProductDataExtract'),
      true,
    ),
    path: '/reports/product-data-extract',
    requiredRole: 'GetProductsDataExtractDetails',
  },
  /**
   * PIM
   */
  {
    breadcrumb: 'PIM',
    component: asyncComponent(
      () => import('../containers/Pim/PimListPage'),
      true,
    ),
    path: '/pim',
    requiredRole: 'ProductGroupDetails',
  },
  {
    breadcrumb: 'Product',
    component: AsyncPimProductGroupPage,
    path: '/pim/:productGroupId(\\d+)',
    exact: false,
    requiredRole: 'ProductGroup',
  },
  {
    breadcrumb: 'Details',
    path: '/pim/:productGroupId(\\d+)/details',
    exact: true,
    requiredRole: 'UpdateProductProduct',
  },
  {
    breadcrumb: 'Variants',
    path: '/pim/:productGroupId(\\d+)/variants',
    exact: true,
    requiredRole: 'UpdateProductProduct',
  },
  {
    breadcrumb: 'Kit List',
    path: '/pim/:productGroupId(\\d+)/kit-list',
    exact: true,
    requiredRole: 'GetProductKit',
  },
  {
    breadcrumb: 'Detail',
    path: '/pim/:productGroupId(\\d+)/(variants|details)/:productId(\\d+)',
    exact: false,
    requiredRole: 'UpdateProductProduct',
  },
  {
    breadcrumb: 'Extended Products',
    path: '/pim/:productGroupId(\\d+)/variants/extended-products',
    exact: false,
    requiredRole: 'UpdateProductProduct',
  },
  {
    breadcrumb: 'Product',
    path:
      '/pim/:productGroupId(\\d+)/variants/extended-products/:productId(\\d+)',
    exact: false,
    requiredRole: 'UpdateProductProduct',
  },
  {
    breadcrumb: 'Buyer',
    path:
      '/pim/:productGroupId(\\d+)/variants/extended-products/:productId(\\d+)/buyer',
    exact: false,
    requiredRole: 'UpdateProductProduct',
  },
  {
    breadcrumb: 'Supplier',
    path:
      '/pim/:productGroupId(\\d+)/variants/extended-products/:productId(\\d+)/supplier',
    exact: false,
    requiredRole: 'UpdateProductProduct',
  },
  {
    // single product versions
    breadcrumb: 'Attributes',
    path: '/pim/:productGroupId(\\d+)/details/:productId(\\d+)/attributes',
    exact: false,
    // requiredRole: 'UpdatePimProductAttributeValues',
  },
  {
    // variant product version
    breadcrumb: 'Attributes/Features',
    path: '/pim/:productGroupId(\\d+)/variants/:productId(\\d+)/attributes',
    exact: false,
    // requiredRole: 'GetProductProductFeatureDetail',
  },
  {
    breadcrumb: 'Features',
    path:
      '/pim/:productGroupId(\\d+)/(variants|details)/:productId(\\d+)/features',
    exact: false,
    requiredRole: 'GetProductProductFeatureDetail',
  },
  {
    breadcrumb: 'Images',
    path:
      '/pim/:productGroupId(\\d+)/(variants|details)/:productId(\\d+)/images',
    exact: false,
    requiredRole: 'UpdateProductProduct', // @todo: change to new role
  },
  {
    breadcrumb: 'Age',
    path:
      '/pim/:productGroupId(\\d+)/(variants|details)/:productId(\\d+)/age-range',
    exact: false,
    requiredRole: 'UpdateProductProduct', // @todo: change to new role
  },
  {
    breadcrumb: 'Associated Products',
    path:
      '/pim/:productGroupId(\\d+)/(variants|details)/:productId(\\d+)/associated-products',
    exact: false,
    requiredRole: 'UpdateProductProduct', // @todo: change to new role
  },
  {
    breadcrumb: 'Descriptions',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/descriptions',
  },
  {
    breadcrumb: 'Curriculum Mapping',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/curriculum-mapping',
  },
  {
    breadcrumb: 'Media',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media',
    exact: true,
  },
  {
    breadcrumb: 'Image',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media/:imageId(\\d)',
    exact: true,
  },
  {
    breadcrumb: 'Assign',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media/:imageId(\\d)/:imageAction',
    exact: true,
  },
  {
    breadcrumb: 'Documents',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media/documents',
  },
  {
    breadcrumb: 'Document',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media/documents/:documentId',
  },
  {
    breadcrumb: 'Videos',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media/videos',
  },
  {
    breadcrumb: 'Video',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/media/videos/:videoId',
  },
  {
    breadcrumb: 'SEO',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/seo',
  },
  {
    breadcrumb: 'Categories',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/categories',
  },
  {
    breadcrumb: 'Pricing',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/pricing',
  },
  {
    breadcrumb: 'Manage Pricing',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/pricing/:productId(\\d+)',
  },
  {
    breadcrumb: 'Clearance',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/pricing/clearance/:productId(\\d+)',
  },
  {
    breadcrumb: 'Amazon',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/amazon',
  },
  {
    breadcrumb: 'Manage Amazon Product',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/amazon/:productId(\\d+)',
  },
  {
    breadcrumb: 'Offers',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/offers',
  },
  {
    breadcrumb: 'Promotions',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/promotions',
  },
  {
    breadcrumb: 'Promotion',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/promotion/:promotionId',
  },
  {
    breadcrumb: 'Selling Channels',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/selling-channels',
  },
  {
    breadcrumb: 'Lifecycle',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/lifecycle',
  },
  {
    breadcrumb: 'Associated Products',
    breadcrumbOnly: true,
    path: '/pim/:productGroupId(\\d+)/associated-products',
  },
  {
    breadcrumb: 'Swagger',
    component: AsyncSwaggerPage,
    path: '/swagger/:apiName?',
    requiredRole: 'InsertSystemUserFindel',
  },
  {
    breadcrumb: 'Playgrounds',
    component: AsyncPlayground,
    path: '/playground',
    requiredRole: 'InsertSystemUserFindel',
    exact: false,
  },
  {
    breadcrumb: 'Playground',
    path: '/playground/:name',
    requiredRole: 'InsertSystemUserFindel',
    breadcrumbOnly: true,
  },
];

export default privateRouteList;
