import * as React from 'react';
// import * as PropTypes from 'prop-types';
// import {
//   withStyles,
//   createStyles,
//   Theme,
//   WithStyles,
// } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import Hidden from '@material-ui/core/Hidden';
// import Divider from '@material-ui/core/Divider';

import { SideNavigation, AccountMenu } from '../Navigation';
import { CoreBaseLayout } from './CoreBaseLayout';
import Typography from '@material-ui/core/Typography/Typography';
import { Logo } from 'components/Logo/Logo';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

import HelpIcon from '@material-ui/icons/Help';

// interface BaseLayoutProps extends WithStyles<typeof styles> {
interface BaseLayoutProps {
  // theme: Theme;
  children?: React.ReactNode;
}

export default function BaseLayout({ children }: BaseLayoutProps) {
  return (
    <CoreBaseLayout
      titleContent={
        <>
          <Box
            flexGrow={1}
            justifyContent="center"
            maxWidth={140}
            mt={1}
            ml={{ xs: 'auto', lg: 0 }}
          >
            <Link to="/" style={{ display: 'block' }}>
              <Typography variant="srOnly" color="inherit" noWrap={true}>
                Pro<b>Digi</b>
              </Typography>
              <Logo />
            </Link>
          </Box>
        </>
      }
      sideContent={<SideNavigation />}
      topContent={
        <>
          <Tooltip title="Help/Documentation" placement="bottom">
            <IconButton
              aria-label="Help"
              component="a"
              href={process.env.REACT_APP_HELP_URL}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <AccountMenu />
        </>
      }
    >
      {children}
    </CoreBaseLayout>
  );
}
