import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      color: '#fff',
      bottom: '2rem',
      left: 0,
      right: 0,
      userSelect: 'none',
      textAlign: 'center',
      zIndex: 5,
    },
    text: {
      cursor: 'zoom-in',
      padding: '0.25rem',
      borderRadius: '0.25rem',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  }),
);

interface ExpandNoticeProps {}

export const ExpandNotice: React.FunctionComponent<ExpandNoticeProps> = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.text}>Click to Expand</span>
    </div>
  );
};

export default ExpandNotice;
