// taken from  https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html

import * as React from 'react';
import Loader from './AsyncComponentLoader';

interface AsyncComponentState {
  component: any;
}

export default function asyncComponent(
  getComponent: any,
  showLoader: boolean = false,
): any {
  class AsyncComponent extends React.Component<{}, AsyncComponentState> {
    public constructor(props: any) {
      super(props);

      this.state = {
        component: null,
      };
    }

    public async componentDidMount() {
      const { default: component } = await getComponent();
      this.setState({
        component,
      });
    }

    public render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : <Loader showLoader={showLoader} />;
    }
  }
  return AsyncComponent;
}
