import { makeStyles, Theme, createStyles } from '@material-ui/core';
import * as React from 'react';

const iconDataUrl =
  "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpolygon points='1,8 7,4 1,0'/%3E%3C/svg%3E";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholder: {
      position: 'relative',
      background: '#000',
      paddingTop: '100%',
      display: 'block',
      '&:after': {
        content: '""',
        display: 'block',
        backgroundImage: `url("${iconDataUrl}")`,
        backgroundPosition: 'center',
        backgroundSize: '20%',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 7,
      },
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    image: {
      display: 'block',
      width: '100%',
    },
  }),
);

interface VideoPlaceholderProps {
  src: string;
}
export const VideoPlaceholder = ({
  src: thumbnailUrl,
}: VideoPlaceholderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.placeholder}>
      <div className={classes.inner}>
        {thumbnailUrl && (
          <img src={thumbnailUrl} className={classes.image} alt="" />
        )}
      </div>
    </div>
  );
};
