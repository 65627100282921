import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';

export interface FormHeadingProps {
  variant?: 'heading' | 'subheading';
  className?: string;
  children: React.ReactNode;
  disableMargin?: boolean;
  'data-cy'?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: FormHeadingProps) => ({
      // color: theme.palette.text.primary,
      marginBottom: props.disableMargin ? 0 : theme.spacing(2),
    }),
  }),
);

export function FormHeading(props: FormHeadingProps) {
  const classes = useStyles(props);

  let variant: TypographyProps['variant'] = 'h5';

  if (props.variant === 'subheading') {
    variant = 'h6';
  }

  return (
    <Typography
      variant={variant}
      className={clsx(props.className)}
      classes={{ root: classes.root }}
      color="textPrimary"
      data-cy={props['data-cy']}
    >
      {props.children}
    </Typography>
  );
}
