import * as React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: FormDividerProps) => {
      const spacing = props.spacing !== undefined ? props.spacing : 4;
      return {
        position: 'relative',
        marginBottom: theme.spacing(spacing),
        marginTop: theme.spacing(spacing),
      };
    },
    titleHolder: {
      position: 'absolute',
      width: '100%',
      top: -theme.spacing(1),
      left: 0,
      textAlign: 'center',
    },
    title: {
      // height: 50,
      color: theme.palette.text.secondary,
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      textTransform: 'uppercase',
    },
  }),
);

interface FormDividerProps {
  spacing?: number;
  title?: string;
}

export function FormDivider(props: FormDividerProps) {
  const classes = useStyles(props);

  if (props.title) {
    return (
      <div className={classes.root}>
        <Divider />
        <div className={classes.titleHolder}>
          <span className={classes.title}>{props.title}</span>
        </div>
      </div>
    );
  }

  return <Divider classes={{ root: classes.root }} />;
}
