import * as React from 'react';
import { VideoPlaceholder } from './VideoPlaceholder';

export interface VideoThumbnailProps {
  videoUrl: string;
  isThumb?: boolean;
}

export const VideoThumbnail = ({
  videoUrl,
  isThumb = false,
}: VideoThumbnailProps) => {
  // if required we can determine type from the url
  const [thumbnail, setThumbnail] = React.useState('');

  React.useEffect(() => {
    if (videoUrl.toLowerCase().includes('vimeo.')) {
      // do vimeo thing
      fetch(`https://vimeo.com/api/oembed.json?url=${videoUrl}`)
        .then(res => res.json())
        .then(data => {
          if (data && data.thumbnail_url) {
            const thumbSize = isThumb ? '_70x70.' : '_320x320.';
            const thumb = data.thumbnail_url.replace(
              /(_[0-9x]*\.)/g,
              thumbSize,
            );
            setThumbnail(thumb);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // shouldn't need to retrigger this

  if (
    videoUrl.toLowerCase().includes('youtube.') ||
    videoUrl.toLowerCase().includes('youtu.be')
  ) {
    // do youTube thing
    const id = videoUrl.split('?v=')[1];

    return (
      <VideoPlaceholder
        src={`http://img.youtube.com/vi/${id}/${
          isThumb ? 'default' : 'hqdefault'
        }.jpg`}
      />
    );
  }

  return <VideoPlaceholder src={thumbnail} />;
};
