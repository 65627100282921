import * as React from 'react';
import Auth from '@aws-amplify/auth';
// import API from '@aws-amplify/api';
// import { ApiName } from '../config/aws';
import { ApiPath } from 'types/endpoints';
import { useGetData, DataActions } from 'hooks/useGetData';
import { SystemUserInfoData } from 'api/products/model/models';

// interface SystemRolePermissionsResponse {
//   data: {
//     isFindelUser: boolean;
//     permissions: ApiPath[];
//   };
//   success: boolean;
//   errorMessages: string[];
// }

// export interface AuthContextState {
//   hasPermission: (permission: ApiPath) => boolean;
//   signOut: (event: any) => void;
//   // authState from amplify
//   authState: string;
//   authData: any;
//   onStateChange: (authState: string, data?: any) => void;
// }

const qsParams = {};

export interface AuthContextState {
  isFindelUser: boolean;
  isLoading: boolean;
  operations: ApiPath[];
  hasPermission: (permission: ApiPath) => boolean;
  signOut: (event: any) => void;
  // authState from amplify
  authState: string;
  authData: null | {
    username?: string;
    attributes?: {
      sub?: string;
      given_name?: string;
      family_name?: string;
      email?: string;
      'custom:IsActive'?: 'True' | 'False';
      'custom:SystemRoleId'?: string;
      'custom:UserId'?: string;
    };
  };
  onStateChange: (authState: string, data?: any) => void;
  userInfo: SystemUserInfoData;
  userInfoActions: DataActions<SystemUserInfoData>;
}

export interface AuthContextProps {
  // extend the aws auth props
  // this could be passed in directly
  // [x: string]: string;
  authState: string;
  authData: any;
  onStateChange: (authState: string, data?: any) => void;
  children: React.ReactNode;
}

const store: AuthContextState = {
  isFindelUser: false,
  isLoading: false,
  operations: [],
  hasPermission: () => false,
  authState: '',
  authData: null,
  onStateChange: () => null,
  signOut: () => null,
  userInfo: {},
  userInfoActions: { getData: () => {}, setState: data => {} },
};

export const AuthContext = React.createContext(store);

export default function AuthProvider(props: AuthContextProps) {
  // note: could possibly add the user object here to make it easier to
  // access elsewhere in the app, assuming it doesn't need to be refreshed
  // constantly

  // const [userId, setUserId] = React.useState<number | null>(null);

  // React.useEffect(() => {
  //   Auth.currentAuthenticatedUser({ bypassCache: true }).then(info => {
  //     console.log(info.attributes);
  //     // user id shoud be passed to SystemUserInfo API
  //     if (info.attributes['custom:userId']) {
  //       setUserId(info.attributes['custom:userId']);
  //     }
  //   });
  //   //
  // }, []);

  // todo: make the qs param not hard coded - should be taken from the tokens
  const [userInfo, userInfoActions] = useGetData<SystemUserInfoData>({
    apiName: 'Systems',
    apiPath: 'SystemUserInfo',
    queryStringParams: qsParams,
    // disabled: !userId, // get id from auth token before enabling
    // showAllData: true,
  });

  // console.log('USER INFO', userInfo);

  const hasPermission = React.useCallback(
    function hasPermission(permission: ApiPath) {
      const hasPerm = userInfo.data?.operations?.includes(permission) || false;

      // console.log(`PERMISSIONS: ${permission} - ${hasPerm}`);
      if (process.env.REACT_APP_BUILD_ENVIRONMENT !== 'prod') {
        // console.info(`PERMISSIONS: ${permission} - ${hasPerm}`);
      }

      return hasPerm;
    },
    [userInfo.data],
  );

  const signOut = React.useCallback(
    function signOut(event: Event) {
      if (event) {
        event.preventDefault();
      }
      Auth.signOut()
        .then(() => {
          props.onStateChange('signedOut', null);
        })
        .catch(err => {
          // tslint:disable-next-line:no-console
        });
    },
    [props],
  );

  const authContextState = React.useMemo<AuthContextState>(() => {
    const ret: AuthContextState = {
      isFindelUser: userInfo.data?.isFindelUser || false,
      authData: props.authData,
      authState: props.authState,
      hasPermission,
      onStateChange: props.onStateChange,
      signOut,
      isLoading: userInfo.isLoading,
      operations: (userInfo.data?.operations || []) as ApiPath[],
      userInfo: userInfo.data || {},
      userInfoActions,
    };
    return ret;
  }, [
    hasPermission,
    props.authData,
    props.authState,
    props.onStateChange,
    signOut,
    userInfo.data,
    userInfo.isLoading,
    userInfoActions,
  ]);

  const { children } = props;

  // console.log('OPERATIONS: ', authContextState.operations)

  console.log(authContextState);

  return (
    <AuthContext.Provider value={authContextState}>
      {children}
    </AuthContext.Provider>
  );
}

export const AuthConsumer = AuthContext.Consumer;
