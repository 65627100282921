// import blueGrey from '@material-ui/core/colors/blueGrey';
// import lightBlue from '@material-ui/core/colors/lightBlue';
import pink from '@material-ui/core/colors/pink';

import createMyTheme from '../helpers/createMyTheme';
import { Shadows } from '@material-ui/core/styles/shadows';

import { primary, secondary, error, success, info, warning } from './colors';

const shadowGen = (scale: number): Shadows => {
  const count = 25;
  const shadows: Shadows = [
    'none',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];

  let i = count - 1; // correct for index
  while (i > 0) {
    shadows[i] = `0 5px ${i * 4 * scale}px -5px rgba(0,0,0,0.3)`;
    i--;
  }
  return shadows;
};

const myShadows = shadowGen(1);

// const primary1 = '#89f7fe';
// const primary2 = '#66a6ff';

// const secondary1 = '#de6262';
// const secondary2 = '#ffb88c';
const theme = (darkMode: boolean = true) => {
  return createMyTheme({
    shadows: myShadows,
    typography: {
      // useNextVariants: true,
    },
    palette: {
      contrastThreshold: 2,
      tonalOffset: 0.1,
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
        // contrastText: '#fff',
      },
      error: {
        main: error,
        light: error,
        dark: error,
      },
      success: {
        //@ts-ignore
        main: success['500'],
        //@ts-ignore
        light: success['100'],
        //@ts-ignore
        dark: success['800'],
        contrastText: 'red',
      },
      info: {
        //@ts-ignore
        main: info['400'],
        contrastText: '#fff',
        //@ts-ignore
        light: info['100'],
        //@ts-ignore
        dark: info['800'],
      },
      warning: {
        main: warning['400'],
        contrastText: '#fff',
        light: warning['100'],
        dark: warning['800'],
      },
    },
    props: {
      MuiInputLabel: {
        // required: true,
      },
      // MuiAppBar: {
      //   color: 'default',
      //   classes: {
      //     colorDefault: 'red',
      //   },
      //   style: {
      //     color: darkMode ? lightBlue['600'] : lightBlue['600'],
      //   }
      // },
    },
    overrides: {
      MuiButton: {
        root: {
          '&$disabled': {
            opacity: 0.3,
          },
        },
        // raisedPrimary: {
        //   // background: `linear-gradient(to bottom right, ${primary1}, ${primary2})`,
        // },
        // raisedSecondary: {
        //   // background: `linear-gradient(to bottom right, ${secondary1}, ${secondary2})`,
        // },
        disabled: {},
      },
      MuiFormLabel: {
        asterisk: {
          color: pink[400],
        },
      },
      MuiAppBar: {
        colorPrimary: {
          // background: `linear-gradient(to bottom right, ${primary1}, ${primary2})`,
        },
      },
      MuiDrawer: {
        paper: {
          border: 0,
          boxShadow: myShadows[4],
        },
        paperAnchorDockedLeft: {
          borderRight: 0,
        },
      },
    },
  });
};

export default theme;
