import 'wdyr';
import 'core-js/features/object/from-entries';
import 'core-js/features/promise/finally';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import 'react-table/react-table.css';
import '@aws-amplify/ui/dist/style.css';
import { unregister } from './registerServiceWorker';
// import { mockServer } from 'api/mock/mirage';

// mockServer();

const rootEl = document.getElementById('root') as HTMLElement;

document.body.addEventListener('focusin', function(ev) {
  const element: Element = ev.target as Element;

  const isFooter = element.closest('.js-form-toolbar');

  const isFormElement = element.closest('form'); // only trigger this for items in the form to stop strange scrolling behaviour

  if (
    !isInViewport(element, { top: 80, bottom: 80, right: 0, left: 0 }) &&
    !isFooter &&
    isFormElement &&
    element.scrollIntoView
  ) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }
});

ReactDOM.render(<App />, rootEl);

unregister();

interface InViewportMargins {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

function isInViewport(
  elem: Element,
  margins: InViewportMargins = { top: 0, bottom: 0, right: 0, left: 0 },
) {
  const bounding = elem.getBoundingClientRect();

  const isTopInView = bounding.top - margins.top >= 0;
  const isLeftInView = bounding.left - margins.left >= 0;
  const isBottomInView =
    bounding.bottom + margins.bottom <=
    (window.innerHeight || document.documentElement.clientHeight);
  const isRightInView =
    bounding.right + margins.right <=
    (window.innerWidth || document.documentElement.clientWidth);

  return isTopInView && isLeftInView && isBottomInView && isRightInView;
}
