import * as React from 'react';
import {
  Drawer,
  Hidden,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';

import TopNavigation from '../Navigation/TopNavigation';

const drawerWidth = 240;

// interface LayoutOptions {
//   mobileZeroPadding?: boolean;
// }

interface CoreBaseLayoutProps {
  // theme: Theme;

  titleContent?: React.ReactNode;
  sideContent?: React.ReactNode;
  topContent?: React.ReactNode;
  children?: React.ReactNode;
  // options?: LayoutOptions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      // height: 430,
      zIndex: 1,
      // overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.background.default,
    },
    toolbar: theme.mixins.toolbar,
    drawerDocked: {
      // flexGrow: 1,
    },
    drawerPaper: {
      width: drawerWidth,
      // backgroundColor: '#ccc',
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
      },
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      margin: '0 auto',
      maxWidth: '100%',

      padding: theme.spacing(3),
      // [theme.breakpoints.down('xs')]: {
      //   paddingLeft:
      //     options && options.mobileZeroPadding ? '0' : theme.spacing(3),
      //   paddingRight:
      //     options && options.mobileZeroPadding ? '0' : theme.spacing(3),
      // },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1400,
      },
    },
  }),
);

export function CoreBaseLayout({
  children,
  sideContent,
  titleContent,
  topContent,
}: // options,
CoreBaseLayoutProps) {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      {/* <div className={classes.toolbar} />
        <Divider /> */}
      {/* <SideNavigation /> */}
      {sideContent}
      {/* <List>{otherMailFolderListItems}</List> */}
    </>
  );

  return (
    <div className={classes.root}>
      <TopNavigation
        titleContent={titleContent}
        handleDrawerToggle={handleDrawerToggle}
        menuContent={topContent}
      />
      <Hidden lgUp={true} implementation="js">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} implementation="js">
        <Drawer
          variant="permanent"
          open={true}
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <div className={classes.content}>
        {
          // spacing for toolbar
          <div className={classes.toolbar} />
        }
        <main>{children}</main>
      </div>
    </div>
  );
}
